import { Layout, Card, Form, Row, Input, Col, Select, Button } from "antd";
import logo from "../../assets/images/idtolu.png";
import { useTranslation } from "react-i18next";
const { Content } = Layout;

export default function Register() {

    const { t } = useTranslation("global");

    return (
        <Layout className="layout-register">
            <Content className="container-register">
                <img src={logo} alt="" />
                <div className="signin">
                    <h1 className="title-register">{ t('register.titleRegister') }</h1>
                    <p>{ t('register.textRegister') }</p>
                </div>
                <Card className="card-register">
                    <h2>{ t('register.register') }</h2>
                    <div className="form-register">
                        <Form layout="vertical">
                            <Row wrap="true" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col xs={24} sm={12} span={8} className='row-space'>
                                    <Form.Item label={ t('register.name') }>
                                        <Input placeholder={ t('register.namePlaceHolder') } />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} span={8} className='row-space'>
                                    <Form.Item label={ t('register.surnames') } >
                                        <Input placeholder={ t('register.surnamePlaceHolder') } />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} span={24} className='row-space'>
                                    <Form.Item label={ t('register.username') } >
                                        <Input placeholder={ t('register.surnamePlaceHolder') } />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} span={24} className='row-space'>
                                    <Form.Item label={ t('register.password') } >
                                        <Input type="password" placeholder={ t('register.passwordPlaceHolder') } />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} span={24} className='row-space'>
                                    <Form.Item label={ t('register.country') }>
                                        <Select size='large' placeholder={ t('register.countryPlaceHolder') }>

                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Button type="primary">{ t('register.saveRegister') }</Button>
                        </Form>
                    </div>
                    <p className="mt-25">{ t('register.textLink')} <a href="/">{ t('register.back') }</a></p>
                </Card>
            </Content>
        </Layout>
    );

}