import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import { Button } from "antd";
import { FaFileExcel } from "react-icons/fa";
import { useTranslation } from "react-i18next";
/**
 *
 * @param {*} dataSet
 * @param {*} fileName
 * @returns component for generate Excel file
 */
export const ReportExcel = (dataSet) => {

  const { t } = useTranslation("global");
  /**
   * file type is define
   */
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  /**
   * funtion for generate file excel
   */
  const exportToExcel = async () => {
    /**
     * the Json is converted to an excel-sheet
     */
    const ws = XLSX.utils.json_to_sheet(dataSet.dataSet);

    // Aplicar estilos a las celdas
    for (const cellAddress in ws) {

      if (cellAddress.startsWith('!')) continue;

      ws[cellAddress].s = {
        alignment: { horizontal: 'center', vertical: 'center' },
        border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } },
      };

      if (cellAddress.includes('1') && cellAddress.length <= 2) {
        ws[cellAddress].s.fill = {
          fgColor: { rgb: '389e0d' },
        }
        ws[cellAddress].s.font = { sz: 14, bold: true }
      }
    }

    /**
     * the book is created
     */
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    /**
     * Attempts to write the workbook data
     */
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    /**
     * expands to support files on the user's system.
     */
    const data = new Blob([excelBuffer], { type: fileType });
    /**
     * the file is save
     */
    FileSaver.saveAs(data, dataSet.fileName);
  };
  return (
    <>
      <Button
        type="primary"
        shape="round"
        onClick={(e) => exportToExcel()}
        icon={<FaFileExcel />}
        style={{
          backgroundColor: "#389e0d",
        }}
      >
        {t('reports.excel')}
      </Button>
    </>
  );
};
export default ReportExcel;
