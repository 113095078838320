import { server } from "./api/service/server/axios.js";
export function getRealm() {
  return "IDT-IDT-DTC-RID-001";
}
export function getClient() {
  return "IDT-IDT-DTC-IOT-005";
}
export function getUUIDClientFront() {
  return "917b43b1-2665-42d5-8ed9-e0202e0d3e0a";
}

export function getUUIDClientBack() {
  return "fc68ff98-e8e2-4003-affd-bc428d462566";
}
export function getURLServerEvent() {
  return `${server()}/ilesa/sse`;
}
