import { List, Switch, message } from "antd";
import requets from "../../../api";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";

export default function Department({ departament, refreshDepartment }) {
    const { keycloak } = useKeycloak();
    const req = requets(keycloak.token);

    const { t } = useTranslation("global");

    const [messageSend, contextHolder] = message.useMessage();

    const onChange = (key, checked) => {
        req.put(`/department/${key}`, { state: checked }).then((response) => {
            if (response.status === 200) {
                refreshDepartment();

                messageSend.open({
                    type: "success",
                    content: t("zones.sucessUpdateDepartment"),
                });
            }
        });
    };

    return (
        <List
            dataSource={departament}
            renderItem={(item) => (
                <List.Item key={item.value}>
                    <List.Item.Meta title={item.label}></List.Item.Meta>
                    {contextHolder}
                    <Switch
                        defaultChecked={/true/.test(item.state)}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        onChange={(checked) => onChange(item.value, checked)}
                    />
                </List.Item>
            )}
        />
    );
}
