import { useTranslation } from "react-i18next";
import SearchDataIndex from "../../../context/share/SearchDataIndex";
import moment from "moment-timezone";

export default function Column() {


    const { t } = useTranslation("global");
    const filter = SearchDataIndex();
    const column =
        [
            {
                title: t("reports.tableColumn.date"), dataIndex: "date", key: "date",
                render: (_, record) => moment(parseInt(record.date)).format("YYYY-MM-DD HH:mm:ss")
            },
            { title: t("reports.tableColumn.nameLamp"), dataIndex: "name", key: "name", ...filter('name') },
            { title: t("reports.tableColumn.dev_eui"), dataIndex: "dev_eui", key: "dev_eui", ...filter('dev_eui') },
            { title: t("reports.tableColumn.voltage"), dataIndex: "voltage", key: "voltage" },
            { title: t("reports.tableColumn.power"), dataIndex: "power", key: "power" },
            { title: t("reports.tableColumn.factoPower"), dataIndex: "power_factor", key: "power_factor" },
            { title: t("reports.tableColumn.current"), dataIndex: "current", key: "current" },
            { title: t("reports.tableColumn.energy"), dataIndex: "energy", key: "energy" },
            { title: t("reports.tableColumn.energy_accumulated"), dataIndex: "energy_accumulated", key: "energy_accumulated" }           
        ];

    return column;

}
