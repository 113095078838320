import { useTranslation } from "react-i18next";
import { Button, Space, message, Switch, Radio, Divider } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import SearchDataIndex from "../../../context/share/SearchDataIndex";
import requets from "../../../api";
import { useKeycloak } from "@react-keycloak/web";

export default function Column(
  getZones,
  form,
  setUuid,
  onChange,
  onChangeDepartament,
  handlerDimerization,
  handlerMessage,
  handleSwitchMode
) {
  const { keycloak } = useKeycloak();
  const req = requets(keycloak.token);

  const getDataIndex = SearchDataIndex();
  const { t } = useTranslation("global");
  const [messageSend, contextHolder] = message.useMessage();

  const handleEditEvent = (key) => {
    req.get(`/zone/${key}`).then(async (response) => {
      if (response.status === 200) {
        await onChangeDepartament(response.data.fkDepartmentUuid);

        form.setFieldsValue(response.data);

        setUuid(response.data.uuid);
      }
    });
  };

  const handleDeleteEvent = (key) => {
    req.delete(`/zone/${key}`).then((response) => {
      if (response.status === 200) {
        getZones();

        messageSend.open({
          type: "success",
          content: t("zones.successDeleted"),
          duration: 10,
        });
      }
    });
  };

  const column = [
    { title: t("zones.tableColumn.id"), dataIndex: "value", key: "value" },
    { title: t("zones.tableColumn.code"), dataIndex: "code", key: "code" },
    {
      title: t("zones.tableColumn.zoneName"),
      dataIndex: "label",
      key: "label",
      ...getDataIndex("label"),
    },
    {
      title: t("device.tableColumn.control"),
      dataIndex: "uuid",
      key: "controls",
      render: (_, record) => (
        <Space>
          <Switch
            //defaultChecked={currentDevice?.mode === "MANUAL" ? true : false}
            //checked={currentDevice?.mode === "MANUAL" ? true : false}
            checkedChildren={t("lamp.manual")}
            unCheckedChildren={t("lamp.automatic")}
            onChange={(checked) => handleSwitchMode(checked, record)}
          />
          <Divider />
          <Switch
            checkedChildren="ON"
            unCheckedChildren="OFF"
            onChange={(checked) => onChange(checked, record)}
          />
          <div className="container__dimerization-slider">
            <Radio.Group
              buttonStyle="solid"
              onChange={(checked) => handlerDimerization(checked, record)}
            >
              <Radio.Button value="ALTO">{t("lamp.high")}</Radio.Button>
              <Radio.Button value="MEDIO">{t("lamp.half")}</Radio.Button>
              <Radio.Button value="INTERM">{t("lamp.middle")}</Radio.Button>
              <Radio.Button value="BAJA">{t("lamp.low")}</Radio.Button>
            </Radio.Group>
          </div>
        </Space>
      ),
    },
    {
      title: t("device.tableColumn.message"),
      dataIndex: "uuid",
      key: "message",
      render: (_, record) => (
        <Space>
          <div className="container__dimerization-slider">
            <Radio.Group
              defaultValue={record.message}
              buttonStyle="solid"
              onChange={(checked) => handlerMessage(checked, record)}
            >
              <Radio.Button value="T=1M">{t("lamp.tm1")}</Radio.Button>
              <Radio.Button value="T=3M">{t("lamp.tm3")}</Radio.Button>
              <Radio.Button value="T=5M">{t("lamp.tm5")}</Radio.Button>
              <Radio.Button value="T=15M">{t("lamp.tm15")}</Radio.Button>
            </Radio.Group>
          </div>
        </Space>
      ),
    },
    {
      title: t("zones.tableColumn.municipality"),
      dataIndex: "counties",
      key: "counties",
      render: (county) => county.label,
    },
    {
      title: t("zones.tableColumn.department"),
      dataIndex: "departments",
      key: "departments",
      render: (department) => department.label,
    },
    {
      title: t("zones.tableColumn.option"),
      dataIndex: "uuid",
      key: "uuid",
      render: (_, record) => (
        <Space direction="vertical">
          <Space>
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => handleEditEvent(record.uuid)}
            >
              {t("zones.edit")}
            </Button>
            {contextHolder}
            <Button
              type="text"
              icon={<DeleteOutlined size={"large"} />}
              onClick={() => handleDeleteEvent(record.uuid)}
            >
              {" "}
              {t("zones.delete")}
            </Button>
          </Space>
        </Space>
      ),
      width: 200,
    },
  ];

  return column;
}
