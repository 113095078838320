import { Input } from 'antd';
const { Search } = Input;
export const SearchBar = ({ t, setSearch }) => {

  const onHandlerSearch = (value) => {
    setSearch(value);
  };
  const onHandlerKeyUp = (event) => {
    setSearch(event.target.value);
  };

  return (
    <Search
      placeholder={t('lamp.searchPlaceholder')}
      onSearch={onHandlerSearch}
      onKeyUp={onHandlerKeyUp}
      size='large'
      enterButton
    />
  );
};
