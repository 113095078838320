import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import requets from "../../../api";
import { useEffect, useState } from "react";
import { message } from "antd";
import moment from "moment";
import { defaultMeasurement } from "../components/default-value";
import { getURLServerEvent } from "../../../config.dev";

const loading = {
  LOADING: true,
  FINISHED: false,
};

export default function useMeasurement(devEui) {
  const { keycloak } = useKeycloak();
  const req = requets(keycloak.token);
  const { t } = useTranslation("global");
  const [messageSend, contextHolder] = message.useMessage();
  const [lastMeasurement, setLastMeasurement] = useState(defaultMeasurement);
  const [loadingMeasurement, setLoadingMeasurement] = useState(
    loading.FINISHED
  );
  //COMMENTADO POR ERRORES
  // const startListeningEvent = (key) => {
  //   const eventSource = new EventSource(
  //     `${getURLServerEvent()}/${key}`
  //   );
  //   eventSource.onopen = () => {
  //     console.log("connected");
  //   };
  //   eventSource.onmessage = (event) => {
  //     let newData = JSON.parse(event.data);
  //     console.log(newData);
  //     setLastMeasurement(newData[0]);
  //     messageSend.open({
  //       type: "success",
  //       content: t("lamp.updatedMeasurement"),
  //     });
  //   };
  //   eventSource.onerror = (error) => {
  //     console.log(error);
  //   };
  //   return () => {
  //     eventSource.close();
  //   };
  // };

  useEffect(() => {
    function fetchLastMeasurement() {
      setLastMeasurement(loading.LOADING);
      req
        .get(`/measurement/${devEui}/last`, {
          start: moment().subtract(1, "days").valueOf(),
          end: moment().valueOf(),
        })
        .then((response) => {
          if (response.status === 200) {
            setLastMeasurement(response.data);
          } else {
            setLastMeasurement(defaultMeasurement);
          }
        })
        .finally(() => setLoadingMeasurement(loading.FINISHED));
    }
    if (!devEui) return;
    fetchLastMeasurement();
    //startListeningEvent(devEui);
  }, [devEui]);

  return {
    loadingMeasurement,
    lastMeasurement,
  };
}
