import { useKeycloak } from '@react-keycloak/web';
import { useState } from 'react';
import requets from '../../../api';
import { message } from 'antd';

const loading = {
  LOADING: true,
  FINISHED: false,
};

export default function useDimerization(devEui) {
  const { keycloak } = useKeycloak();
  const req = requets(keycloak.token);
  const [messageSend, contextHolder] = message.useMessage();
  const [loadingSpining, setLoadingSpining] = useState(loading.FINISHED);

  function fetchPostControl(action) {
    return req
      .post(`/device/control/${devEui}`, action)
      .then((response) => response);
  }

  function fetchPutDevice(value) {
    return req
      .put(`/device/${devEui}`, value)
      .then((response) => response);
  }

  return {
    fetchPutDevice,
    fetchPostControl,
    messageSend,
    contextHolder,
    loadingSpining,
    setLoadingSpining,
  };
}
