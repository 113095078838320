import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';

import requets from '../../../api';

export default function useGraphics({ list }) {
  const { keycloak } = useKeycloak();
  const req = requets(keycloak.token);
  const [measurement, setMeasurement] = useState({
    energy: [],
    measurement: [],
  });
  const [params, setParams] = useState();

  useEffect(() => {
    function fetchMasurementByList() {
      req.post(`/measurement/bygroup`, list, params).then((response) => {
        if (response.status === 200) {
          setMeasurement(response.data);
        }
      });
    }
    fetchMasurementByList();
  }, [params]);
  return {
    measurement,
    setParams,
  };
}
