import { Button, Space } from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from '@ant-design/icons';


export default function Column (deleteUser) {

    const { t } = useTranslation("global");
    const column = [
        {title: t("user.tableColumn.email"), dataIndex: "email" , key:  "email"},
        {title: t("user.tableColumn.name"), dataIndex: "name" , key:  "name"},
        {title: t("user.tableColumn.surname"), dataIndex: "lastName" , key:  "lastName"},
        {title: t("user.tableColumn.rol"), dataIndex: "roles" , key:  "roles", 
        render: (_, record) => {
          const roles = JSON.parse(record.roles);
          return (
            <span>{roles.name}</span>
          );
        }},
        {title: t("user.tableColumn.username"), dataIndex: "username" , key:  "username"},
        {title: t("user.tableColumn.phone"), dataIndex: "phone" , key:  "phone"},
        {title: t("user.tableColumn.options"), dataIndex: "id" , key:  "id",
            render: (_, record) => (
                <Space>
                    <Space>
                        <Button type="text" icon={< DeleteOutlined  size={'large'}/>}
                            onClick={() => deleteUser(record.id) }> { t('user.delete') }
                        </Button>
                    </Space>
                </Space>
            )
        },
    ];

    return column;
}