export const defaultMeasurement = {
  dev_eui: '',
  date: '',
  current: { value: 0, status: '' },
  dimerization: 0,
  energy: { value: 0, status: '' },
  energy_accumulated: 0,
  frequency: { value: 0, status: '' },
  latitude: '',
  longitude: '',
  power: { value: 0, status: '' },
  power_factor: { value: 0, status: '' },
  state: 0,
  status: '',
  uuid_application: '',
  voltage: { value: 0, status: '' },
};
