import { Line } from '@ant-design/plots';

export default function PlotGraphics({ data, unit, multiple = false }) {
  const config = {
    data,
    padding: 'auto',
    xField: 'date',
    yField: 'scales',
    xAxis: {
      tickCount: 5,
    },
    yAxis: {
      label: {
        formatter: (text) => `${text} ${unit ? unit: ''}`,
      },
    },
    slider: {
      start: 0,
    },
  };

  if (multiple) {
    config.colorField = 'steelblue';
    config.seriesField = 'division';
  }

  return <Line {...config} />;
}
