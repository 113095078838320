import { Button, Col, Row, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import QrScanner from 'qr-scanner';
import 'qr-scanner/qr-scanner-worker.min.js';

export default function Scanner({ form, handleTabChange }) {

    const videoRef = useRef(null);
    const [scanning, setScanning] = useState(false);
    const [scanner, setScanner] = useState(null);

    useEffect(() => {

        return () => {

            if (scanner) {
                scanner.stop();
            }
        }

    }, [scanner]);


    const startScanner = () => {

        setScanning(true);

        const qrscanner = new QrScanner(videoRef.current, async (result) => {
            const data = result.data.split(',');
            if (data) {
                form.setFieldsValue({
                    devEui: data[0],
                    joinEui: data[1],
                    appKey: data[2]
                });

                qrscanner.stop();
                setScanning(false);
                await handleTabChange(2);
            }
        },
            {
                highlightScanRegion: true,
                highlightCodeOutline: true
            });

        qrscanner.start();

        setScanner(qrscanner);
    };

    const stopScanner = () => {

        setScanning(false);

        if (scanner) {
            scanner.stop();
        }
    };


    return (
        <>
            <h2>QR Scanner: </h2>
            <div>
                <div>
                    <Row justify="center" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={24} sm={24} md={18} lg={18} span={18}>
                            <video ref={videoRef} autoPlay playsInline style={{ width: '100%', height: 'auto' }}></video>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} span={18} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Space>
                                <Button onClick={startScanner} disabled={scanning}>
                                    Iniciar Escáner
                                </Button>
                                <Button onClick={stopScanner} disabled={!scanning}>
                                    Detener Escáner
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </div>

            </div>
        </>
    );
}