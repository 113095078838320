/**dependecies */
import { useNavigate } from "react-router-dom";
import { Card, Typography, Row, Col } from "antd";
import Meta from "antd/es/card/Meta.js";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Marker, MapContainer, TileLayer, Popup } from "react-leaflet";
import L from "leaflet";
//import { renderToStaticMarkup } from "react-dom/server";

/**modules */
import marker from "../test/marker.js";
/**icons */
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import IconLampSuccess from "../assets/images/iconMapMarkerSuccess.svg";
import IconLampOn from "../assets/images/iconMapMakerOn.svg";
import IconMarkerFail from "../assets/images/iconMapMarkerFail.svg";
import LAMP_DEFAULT from "../assets/images/lamp-default.png";
import "leaflet/dist/leaflet.css";
import MarkerClusterGroup from "react-leaflet-cluster";
import requets from "../api/index.js";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

export default function Home() {
  const { keycloak } = useKeycloak();
  const req = requets(keycloak.token);
  const controlPanel = useSelector((state) => state.controlPanel);
  const navigate = useNavigate();
  const { t } = useTranslation("global");
  const [devices, setDevices] = useState([]);
  const iconLampFail = L.icon({
    iconUrl: IconMarkerFail,
    iconSize: [25, 25],
    iconAnchor: [12, 41],
    popupAnchor: [0, -34],
  });

  const iconLampSuccess = L.icon({
    iconUrl: IconLampSuccess,
    iconSize: [25, 25],
    iconAnchor: [12, 41],
    popupAnchor: [0, -34],
  });

  const iconLampOn = L.icon({
    iconUrl: IconLampOn,
    iconSize: [25, 25],
    iconAnchor: [12, 41],
    popupAnchor: [0, -34],
  });

  useEffect(() => {
    const { department, county, zone, devEui } = controlPanel;
    req
      .get("/device/filter/", { department, county, zone, devEui })
      .then((response) => {
        setDevices(response.data);
      });
  }, [controlPanel]);
  //NO BORRAR
  // const svgString = renderToStaticMarkup(
  //   <FaMapMarkerAlt
  //     color="red"
  //     style={{
  //       border: "1px solid black",
  //       borderRadius: "100%",
  //       padding: "5px",
  //     }}
  //   />
  // );
  return (
    <>
      <div className="layout-content">
        <div>
          <Card className="mb-24" title={t("home.map")}>
            <MapContainer
              center={marker[0].coordinates}
              zoom={14}
              scrollWheelZoom={true}
              style={{ height: "700px", width: "100%" }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <MarkerClusterGroup  maxClusterRadius={20} animate={true}>
                {devices
                  .filter((marker) => marker.isActive === true)
                  .map((marker) => {
                    let iconLamp =
                      marker.status === "OK" && marker.state === "ON"
                        ? iconLampOn
                        : marker.status === "OK" && marker.state === "OFF"
                        ? iconLampSuccess
                        : iconLampFail;

                    return (
                      <Marker
                        key={marker.devEui}
                        position={marker.location}
                        icon={iconLamp}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Popup className="marker-pup">
                            <Card
                              style={{
                                width: "410px",
                              }}
                              cover={
                                <img
                                  alt="example"
                                  style={{ objectFit: "contain" }}
                                  width={80}
                                  height={150}
                                  src={LAMP_DEFAULT}
                                />
                              }
                              actions={[
                                <EyeOutlined
                                  key="eye"
                                  onClick={() =>
                                    navigate("/lamp/", {
                                      state: { device: marker },
                                    })
                                  }
                                />,
                                <EditOutlined
                                  key="edit"
                                  onClick={() =>
                                    navigate(`/device/${marker.devEui}/edit`)
                                  }
                                />,
                              ]}
                            >
                              <Meta
                                title={
                                  <Text strong>
                                    {marker.name.toUpperCase()}
                                  </Text>
                                }
                                description={
                                  <Row
                                    gutter={[0, 4]}
                                    style={{ width: "500px" }}
                                  >
                                    <Col span={12}>
                                      {t("home.marquille")}:
                                      <span className="space-marker">
                                        <strong>{marker.marquille}</strong>
                                      </span>
                                    </Col>
                                    <Col span={12}>
                                      {t("home.status")}:
                                      <span
                                        className="space-marker"
                                        style={
                                          marker.status === "OK"
                                            ? {
                                                color: "green",
                                                fontWeight: 700,
                                              }
                                            : { color: "red", fontWeight: 700 }
                                        }
                                      >
                                        {marker.status === "OK"
                                          ? t("home.success")
                                          : t("home.fail")}
                                      </span>
                                    </Col>
                                    <Col span={12}>
                                      {t("home.description")}:
                                      <span className="space-marker">
                                        {marker.description}
                                      </span>
                                    </Col>
                                    <Col span={12}>
                                      {t("home.state")}:
                                      <span
                                        className="space-marker"
                                        style={
                                          marker.state === "ON"
                                            ? {
                                                color: "green",
                                                fontWeight: 700,
                                              }
                                            : {
                                                color: "#0000fd",
                                                fontWeight: 700,
                                              }
                                        }
                                      >
                                        {marker.state}
                                      </span>
                                    </Col>
                                    <Col span={12}>
                                      {t("home.dateInstallating")}:
                                      <span className="space-marker">
                                        {marker.installationDate}
                                      </span>
                                    </Col>
                                    <Col span={12}>
                                      {t("home.power")}:
                                      <span className="space-marker">
                                        <strong>{marker.power}</strong>
                                      </span>
                                    </Col>
                                    <Col span={12}>
                                      {t("home.manufacturingdate")}:
                                      <span className="space-marker">
                                        {marker.manufacturingDate}
                                      </span>
                                    </Col>
                                    <Col span={12}>
                                      {t("home.brand")}:
                                      <span className="space-marker">
                                        {marker.brand}
                                      </span>
                                    </Col>
                                    <Col span={14}>
                                      {t("home.location")}:
                                      <span className="space-marker">
                                        <strong>
                                          {marker.location[0]},{" "}
                                          {marker.location[1]}
                                        </strong>
                                      </span>
                                    </Col>
                                  </Row>
                                }
                              />
                            </Card>
                          </Popup>
                        </div>
                      </Marker>
                    );
                  })}
              </MarkerClusterGroup>
            </MapContainer>
          </Card>
        </div>
      </div>
    </>
  );
}
