import { useTranslation } from "react-i18next";
import { WhatsAppOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Space, Tag } from "antd";
import moment from "moment-timezone";

export default function Column(){

    const { t } = useTranslation("global");

    const column = 
    [
        {title: t("alerts.tableColumn.date"), dataIndex: "date" , key:  "date",
            render: (_, record) => moment(parseInt(record.date)).format("YYYY-MM-DD HH:mm:ss")
        },
        {title: t("alerts.tableColumn.state"), dataIndex: "state" , key:  "state",
        render: (_, record) => {
        
           let color = record.status === 'Success' ? 'green' : 'red';
           let state = record.status === 'Success' ? 'device.success' : 'device.failed';
           return (
               <Tag color={color} key={record.state}>   
                   {t(state)}
               </Tag> 
           )
        }},
        {title: t("alerts.tableColumn.status"), dataIndex: "status" , key:  "status",
        render: (_, record) => (
           <Button type="text">
               {t('alerts.open')}
           </Button>
        )},
        {title: t("alerts.tableColumn.channel"), dataIndex: "channel", key:  "channel",
        render: (_, record) => (
            <Space direction="vertical" align="center">
                <Space wrap="false">
                    <div className="channel-button">
                        <Button shape="circle" icon={<WhatsAppOutlined style={{ color: '#2A60B7' }} />} style={{ backgroundColor: '#BAE5FF' }}>
                        </Button>
                        <Button shape="circle" icon={<MailOutlined style={{ color: '#9CD323' }} />}>
                        </Button>
                    </div>
                </Space>
            </Space>
        )},
        {title: t("alerts.tableColumn.name"), dataIndex: "device" , key:  "device",
            render: (device) => device.name
        },
        {title: t("alerts.tableColumn.eui"), dataIndex: "fkDevEui" , key:  "fkDevEui"},
        {title: t("alerts.tableColumn.description"), dataIndex: "description" , key:  "description"},
        
    ];

    return column;

}
