import { createSlice } from "@reduxjs/toolkit";

const initialState = {};
const controlPanelSlice = createSlice({
  name: "controlPanel",
  initialState,
  reducers: {
    setFilters: (state, action) => {
      const { date, department, county, zone, devEui } = action.payload;

      state.date = date;
      state.department = department;
      state.county = county;
      state.zone = zone;
      state.devEui = devEui;
    },
    setDate: (state, action) => {
      const { date } = action.payload;
      state.date = date;
    },
    setDepartment: (state, action) => {
      const { department } = action.payload;
      state.department = department;
    },
    setCounty: (state, action) => {
      const { county } = action.payload;
      state.county = county;
    },
    setZone: (state, action) => {
      const { zone } = action.payload;
      state.zone = zone;
    },
    setDevEui: (state, action) => {
      const { devEui } = action.payload;
      state.devEui = devEui;
    },
  },
});

export const {
  setFilters,
  setDate,
  setDepartment,
  setCounty,
  setZone,
  setDevEui,
} = controlPanelSlice.actions;
export default controlPanelSlice.reducer;
