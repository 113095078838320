import { useKeycloak } from "@react-keycloak/web";
import { Card } from "antd";
import { useTranslation } from "react-i18next";

export default function Profile() {

    const { keycloak } = useKeycloak();
    const { name, email, family_name } = keycloak.tokenParsed;
    const { t } = useTranslation("global");

    const styles = { fontWeight: '700' };
    
    return (
        <Card style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
            <p><span style={styles}>{t('user.form.name')}:</span> {name}</p>
            <p><span style={styles}>{t('user.form.username')}:</span> {family_name}</p>
            <p><span style={styles}>{t('user.form.email')}:</span> {email}</p>
        </Card>
    );
}