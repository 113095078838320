import { Layout, Space, Row, Col, Button, Avatar, Image, Menu, Divider, Carousel, Card, Statistic } from "antd";
import { useKeycloak } from "@react-keycloak/web";
import { Link } from "react-router-dom";
import {
  InstagramOutlined,
  ControlOutlined,
  TwitterOutlined,
  YoutubeOutlined,
  AntCloudOutlined,
  WifiOutlined,
  ThunderboltOutlined,
  ArrowUpOutlined
} from '@ant-design/icons';
import IDT from "../../assets/images/Ilesa2.svg";
import CELDA1 from "../../assets/images/fotocelda1.png";
import CELDA2 from "../../assets/images/fotocelda2.png";
import CELDA3 from "../../assets/images/fotocelda3.png";
import { useTranslation } from "react-i18next";

const { Footer } = Layout;

export default function SinglePage() {

  const { keycloak } = useKeycloak();

  const { t } = useTranslation("global");

  const item = [
    {
      label: (
        <a href="#home">{t('singlePage.home')}</a>
      ),
      key: 'home',

    },
    {
      label: (
        <a href="#statisc">{t('singlePage.statistcs')}</a>
      ),
      key: 'statistcs'
    },
    {
      label: (
        <a href="#galery">{t('singlePage.galery')}</a>
      ),
      key: 'galery'
    }
  ];

  return (
    <Layout className="single">
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        style={{ position: "fixed", width: "100%", zIndex: "999", backgroundColor: "#fff", padding: "10px" }}>
        <Col span={8} xs={24} sm={12} md={8} lg={8}>
          <img className="single__header-img" src={IDT} alt="logo" />
        </Col>
        <Col span={8} xs={24} sm={12} md={8} lg={8} >
          <div style={{ width: '100%' }}>
            <Menu mode="horizontal" items={item} style={{ display: 'flex', justifyContent: 'center' }} />
          </div>
        </Col>
        <Col span={8} xs={24} sm={12} md={8} lg={8}>
          <Space>
            <Button type="primary" shape="round" onClick={() => keycloak.login()}>{t('singlePage.signOn')}</Button>
          </Space>
        </Col>
      </Row>
      <Row id="home" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="center">
        <Col span={24} xs={24} sm={24} md={24} lg={24}>
          <h1 className="single__content__header-h2">
            {t('singlePage.benefits')}
          </h1>
        </Col>
        <Col span={24} xs={24} sm={24} md={24} lg={24} style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden', height: '200px' }}>
          <div className="single__content__circle"></div>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start">
        <Col span={8} xs={12} sm={12} md={12} lg={8}>
          <h2 style={{ fontSize: '34px', fontWeight: '700' }}>
            {t('singlePage.characteristics')}
          </h2>
        </Col>
      </Row>
      <div style={{ marginLeft: '15%', marginRight: '15%' }}>
        <Row wrap="true" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12} xs={24} sm={24} md={12} lg={12}>
            <Space size={24}>
              <Avatar size={64} icon={<ControlOutlined />} />
              <div style={{ textAlign: 'left' }}>
                <h3>
                  {t('singlePage.characteristicsOne')}
                </h3>
                <p>
                  {t('singlePage.subCharacteristicsOne')}
                </p>
              </div>
            </Space>
          </Col>
          <Col span={12} xs={24} sm={24} md={12} lg={12}>
            <Space size={24}>
              <Avatar size={64} icon={<AntCloudOutlined />} />
              <div style={{ textAlign: 'left' }}>
                <h3>
                  {t('singlePage.characteristicsTwo')}
                </h3>
                <p>
                  {t('singlePage.subCharacteristicsTwo')}
                </p>
              </div>
            </Space>
          </Col>
          <Col span={12} xs={24} sm={24} md={12} lg={12}>
            <Space size={16}>
              <Avatar size={64} icon={<ThunderboltOutlined />} />
              <div style={{ textAlign: 'left' }}>
                <h3>
                  {t('singlePage.characteristicsThree')}
                </h3>
                <p>
                  {t('singlePage.subCharacteristicsThree')}
                </p>
              </div>
            </Space>
          </Col>
          <Col span={12} xs={24} sm={24} md={12} lg={12}>
            <Space size={16}>
              <Avatar size={64} icon={<WifiOutlined />} />
              <div style={{ textAlign: 'left' }}>
                <h3>
                  {t('singlePage.characteristicsFour')}
                </h3>
                <p>
                  {t('singlePage.subCharacteristicsFour')}
                </p>
              </div>
            </Space>
          </Col>
        </Row>
        <Row wrap="true" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="center">
          <Col span={6} xs={24} sm={24} md={12} lg={6}>
            <Image width={200} height={200} src={`${CELDA1}`} />
          </Col>
          <Col span={6} xs={24} sm={24} md={12} lg={6}>
            <Image width={200} height={200} src={`${CELDA2}`} />
          </Col>
          <Col span={6} xs={24} sm={24} md={12} lg={6}>
            <Image width={200} height={200} src={`${CELDA3}`} />
          </Col>
          <Col span={6} xs={24} sm={24} md={12} lg={6}>
            <Image width={200} height={200} src={`${CELDA1}`} />
          </Col>
        </Row>
      </div>
      <Divider />
      <Divider />
      <Footer className="single__footer">
        <div className="white">
          <Space>
            {t('singlePage.copyright')}
          </Space>
        </div>
        <div>
          <Space>
            <Link className="white">{t('singlePage.about')}</Link>
            <Link className="white">{t('singlePage.contact')}</Link>
            <Link className="white">{t('singlePage.help')}</Link>
          </Space>
        </div>
        <div>
          <Space>
            <InstagramOutlined className="icons" />
            <TwitterOutlined className="icons" />
            <YoutubeOutlined className="icons" />
          </Space>
        </div>
      </Footer>
    </Layout>
  );
}