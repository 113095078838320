const marker = [
  {
    id: 1,
    name: "lamp-",
    coordinates: [9.300427048307242, -75.39176054497695],
    status:true
  },
  {
    id: 2,
    name: "lamp-",
    coordinates: [9.307270094606892, -75.39223180587989],
    status: false
  },
  {
    id: 3,
    name: "lamp-",
    coordinates: [9.307801587744663, -75.39997394928393],
    status: false
  },
  {
    id: 4,
    name: "lamp-",
    coordinates: [9.309993988394368, -75.38172942004469],
    status: true  
  },
  {
    id: 5,
    name: "lamp-",
    coordinates: [9.311189837498247, -75.39068337719876],
    status: false
  },
  {
    id: 6,
    name: "lamp-",
    coordinates: [9.301755805636006, -75.40448458935394],
    status: false
  },
  {
    id: 7,
    name: "lamp-",
    coordinates: [9.298633217908574, -75.39660779997756],
    status: true
  },
  {
    id: 8,
    name: "lamp-",
    coordinates: [9.300692800175952, -75.38395107858699],
    status: true
  },
  {
    id: 9,
    name: "lamp-",
    coordinates: [9.292055761120011, -75.40293616067333],
    status: true
  },
];
export default marker;
