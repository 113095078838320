import Dimerization from './dimerization';
import { Graphics } from './graphics';
import { Measurement } from './measurements';

export const itemsTaps = ({t, device, data}) => {
  if (!device) return;
  const items = [
    {
      key: 1,
      label: t('lamp.measurementTaps'),
      children: <Measurement device={device} />,
    },
    {
      key: 2,
      label: t('lamp.dimerizationTaps'),
      children: <Dimerization t={t} device={device} />,
    },
    {
      key: 3,
      label: t('lamp.graphics'),
      children: <Graphics device={device} data={data} />
    }
  ];

  return items;
};
