import { Button, Result } from "antd";
import { Link } from "react-router-dom";
export default function UnAthorized() {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Link to="/">
          <Button type="primary" shape="round">Back Home</Button>
        </Link>
      }
    />
  );
}
