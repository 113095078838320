import { useTranslation } from "react-i18next";
import { Button, Space } from "antd";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import SearchDataIndex from "../../../context/share/SearchDataIndex";



export default function Column(handleDeleteEvent, handleEditEvent) {

    const getDataIndex = SearchDataIndex();

    const { t } = useTranslation("global");

    const column =
        [
            
            {
                title: t("config.tableColumn.nameLamp"), dataIndex: "name",  key: "device", ...getDataIndex('device.name'),
                render: (_, record) => record.device.name
            },
            {
                title: t("config.current"), children: [
                    {
                        title: t("config.min"),
                        dataIndex: ['current', 0],
                    }, {
                        title: t("config.max"),
                        dataIndex: ['current', 1]
                    }
                ]
            },
            {
                title: t("config.powerFactor"), children: [
                    {
                        title: t("config.min"),
                        dataIndex: ['powerFactor', 0],
                    }, {
                        title: t("config.max"),
                        dataIndex: ['powerFactor', 1],
                    }
                ]

            },
            {
                title: t("config.power"), children: [
                    {
                        title: t("config.min"),
                        dataIndex: ['power', 0],
                    }, {
                        title: t("config.max"),
                        dataIndex: ['power', 1],
                    }
                ]
            },
            {
                title: t("config.frequency"), children: [
                    {
                        title: t("config.min"),
                        dataIndex: ['frequency', 0],
                    }, {
                        title: t("config.max"),
                        dataIndex: ['frequency', 1],
                    }
                ]
            },
            {
                title: t("config.energy"), children: [
                    {
                        title: t("config.min"),
                        dataIndex: ['energy', 0],
                    }, {
                        title: t("config.max"),
                        dataIndex: ['energy', 1],
                    }
                ]
            },
            {
                title: t("config.tableColumn.location"), children: [
                    {
                        title: "LOG",
                        dataIndex: 'device',
                        render: (_, record) => record.device.location[0]
                    }, 
                    {
                        title: "LAT",
                        dataIndex: "device",
                        render: (_, record) => record.device.location[1]
                    }
                ]
            },
            {
                title: t("device.tableColumn.option"), dataIndex: "uuid", key: "uuid",
                render: (_, record) => (
                    <Space direction="vertical">
                        <Space>
                            <Button type="text" icon={< EditOutlined />}
                                onClick={() => handleEditEvent(record.uuid)}>
                                {t('device.edit')}
                            </Button>
                            <Button type="text" icon={< DeleteOutlined size={'large'} />}
                                onClick={() => handleDeleteEvent(record.uuid)}> {t('device.delete')}
                            </Button>
                        </Space>
                    </Space>
                )
            },
        ];

    return column;

}
