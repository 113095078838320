import { CSVLink } from "react-csv";
import { Button } from "antd";
import { FaFileCsv } from "react-icons/fa";
import { useTranslation } from "react-i18next";
export const ReportCsv = (dataSet) => {
  const { t } = useTranslation("global");
  return (
    <>
      <CSVLink
        data={dataSet.dataSet}
        filename={dataSet.fileName}
      >
        <Button type="primary" shape="round" icon={<FaFileCsv />}  
        style={{
          backgroundColor: "#389e0d",
        }}>
        {t('reports.csv')}
        </Button>
      </CSVLink>
    </>
  );
};
