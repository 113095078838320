import { Card, Form, Layout, Table, message } from 'antd';
import FormConfig from './components/form.config';
import columnConfig from './table/data.table';
import requets from '../../api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';

const { Content } = Layout;

export default function Config() {
    const { keycloak } = useKeycloak();
    const req = requets(keycloak.token);
    const [form] = Form.useForm();

    const { t } = useTranslation("global");

    const [messageSend, contextHolder] = message.useMessage();

    const [ranger, setRanger] = useState([]);

    const [uuid, setUuid] = useState("");

    

    const getRanger = () => {

        req.get('/ranger/').then(response => {
            setRanger(response.data);
        });
    }


    useEffect(() => {
        getRanger();
    }, [])


    const createRanger = (value) => {
        req.post('/ranger/', value).then(response => {
            if (response.status === 201) {
                getRanger();
                messageSend.open({
                    type: "success",
                    content: t("config.successCreated"),
                });
                form.resetFields();
            }
        });
    }

    const updateRanger = (value) => {
        req.put(`/ranger/${uuid}`, value).then(response => {
            if (response.status === 200) {
                getRanger();
                messageSend.open({
                    type: "success",
                    content: t("config.successUpdated"),
                });
                form.resetFields();
                setUuid('');
            }
        });
    }


    const onFinish = (value) => {
       
        uuid ? updateRanger(value) : createRanger(value);
    }

    const handleEditEvent = (key) => {
        req.get(`/ranger/${key}`).then((response) => {
            if (response.status === 200) {
      
                setUuid(key);

                form.setFieldsValue(response.data);
            }
        });
    };

    const handleDeleteEvent = (key) => {
        req.delete(`/ranger/${key}`).then((response) => {
            if (response.status === 200) {
                getRanger();

                messageSend.open({
                    type: "success",
                    content: t("config.successDeleted"),
                    duration: 10,
                });
            }
        });
    };

    return (
        <Content>
            <Card>
                {contextHolder}
                <FormConfig
                    form={form}
                    onFinish={onFinish}
                    uuid={uuid}
                />
                <Table rowKey="fkDevEui" columns={columnConfig(handleDeleteEvent, handleEditEvent)} dataSource={ranger} scroll={{  x: 'calc(700px + 50%)' }}></Table>
            </Card>
        </Content>
    );
}