import * as React from 'react';
const SVGComponent = ({ color = '#B6B5A7' }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    width='215.9mm'
    height='79.4mm'
    viewBox='0 0 21590 27940'
    style={{
      shapeRendering: 'geometricPrecision',
      textRendering: 'geometricPrecision',
      imageRendering: 'optimizeQuality',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
    }}
  >
    <defs>
      <style>
        {`.fil4 {fill:${color};}`}
        {`.fil3 {fill:#7F8180;}`}
        {`.fil1 {fill:#7F8180;}`}
        {`.fil0 {fill:#9E9E9E;}`}
        {`.fil2 {fill:#9E9E9E;}`}
      </style>
    </defs>
    <g id='Capa_x0020_1'>
      <metadata id='CorelCorpID_0Corel-Layer' />
      <g id='_2144787191712'>
        <path
          className='fil0'
          d='M9900.39 25075.86l1021.98 0 297.94 0 1021.98 0 0 -209.05c-14.61,-94.97 -75.04,-163.04 -200.58,-192.95 -263.28,-54.85 -196.37,-128.64 -572.89,-128.64l-248.51 0 -297.94 0 -248.51 0c-376.52,0 -309.61,73.79 -572.89,128.64 -125.54,29.91 -185.97,97.98 -200.58,192.95l0 209.05z'
        />
        <polygon
          className='fil1'
          points='10325.48,24602.05 10974.01,24602.05 11168.67,24602.05 11817.2,24602.05 11671.78,21592.07 11168.67,21592.07 10974.01,21592.07 10470.9,21592.07 '
        />
        <path
          className='fil2'
          d='M10446.02 21610.87l589.75 0 71.14 0 589.75 0c26.79,-24.64 33.38,-67.95 18.37,-131.18 -34.46,-133.22 -68.92,-266.44 -103.38,-399.65 -13.26,-50.11 -34.36,-69.01 -60.38,-68.19l-444.36 0 -6.69 0 -1.35 0 -6.73 0 -1.35 0 -6.69 0 -1.35 0 -6.7 0 -1.35 0 -6.72 0 -1.35 0 -6.7 0 -1.35 0 -6.69 0 -1.35 0 -6.73 0 -1.32 0 -6.72 0 -444.36 0c-26.02,-0.82 -47.12,18.08 -60.38,68.19 -34.46,133.21 -68.92,266.43 -103.38,399.65 -15.01,63.23 -8.42,106.54 18.37,131.18z'
        />
        <polygon
          className='fil2'
          points='10799.29,21011.85 11016.36,21011.85 11126.32,21011.85 11343.39,21011.85 11343.39,3232.32 11126.32,3232.32 11016.36,3232.32 10799.29,3232.32 '
        />
        <path
          className='fil1'
          d='M10760.25 15599.1l297.91 0 26.36 0 297.91 0c78.64,0 142.95,64.32 142.95,142.96l0 36.5c0,78.64 -64.31,142.96 -142.95,142.96l-297.91 0 -26.36 0 -297.91 0c-78.64,0 -142.95,-64.32 -142.95,-142.96l0 -36.5c0,-78.64 64.31,-142.96 142.95,-142.96z'
        />
        <path
          className='fil1'
          d='M10760.25 9024.48l297.91 0 26.36 0 297.91 0c78.64,0 142.95,64.32 142.95,142.95l0 36.5c0,78.64 -64.31,142.96 -142.95,142.96l-297.91 0 -26.36 0 -297.91 0c-78.64,0 -142.95,-64.32 -142.95,-142.96l0 -36.5c0,-78.63 64.31,-142.95 142.95,-142.95z'
        />
        <path
          className='fil1'
          d='M10788.89 3990.6l269.27 0 26.36 0 269.27 0c26.81,0 48.73,21.91 48.73,48.73l0 1309.7c0,26.81 -21.92,48.73 -48.73,48.73l-269.27 0 -26.36 0 -269.27 0c-26.81,0 -48.73,-21.92 -48.73,-48.73l0 -1309.7c0,-26.82 21.92,-48.73 48.73,-48.73z'
        />
        <path
          className='fil3'
          d='M10813.67 2564.33l244.49 0 26.36 0 244.49 0c40.45,0 73.51,33.06 73.51,73.53l0 499.31c0,52.35 -42.8,95.15 -95.16,95.15l-222.84 0 -26.36 0 -222.84 0c-52.36,0 -95.16,-42.8 -95.16,-95.15l0 -499.31c0,-40.47 33.06,-73.53 73.51,-73.53z'
        />
        <path
          className='fil2'
          d='M10740.16 4886.33c-1569.12,-718.34 -3237.71,-1164.71 -5007.38,-1292.63l17.39 -108.18c14.69,-91.61 7.7,-163.23 -13.79,-182.95 1686.85,173.74 3379.9,468.55 5003.78,1084.19l0 499.57z'
        />
        <path
          className='fil1'
          d='M3232.33 2796.06l2218.13 356.56c61.01,9.8 235.42,103.63 285.92,149.95 21.49,19.72 28.48,91.34 13.79,182.95l-30.15 187.56c-10.8,67.26 -74.64,113.44 -141.92,102.64l-2595.5 -417.22c-67.29,-10.83 -113.45,-74.7 -102.62,-141.95l30.15 -187.56c24.53,-152.67 169.5,-257.44 322.2,-232.93z'
        />
        <path
          className='fil2'
          d='M3192.76 2789.7l2289.85 368.09c-23.37,-47.38 -181.18,-205.77 -228.97,-213.45l-1723.6 -277.07c-66.74,-10.73 -296.01,110.03 -337.28,122.43z'
        />
        <path
          className='fil4'
          d='M5592.24 3777.15c-33.71,112.3 -146.92,185.68 -265.67,166.59l-2158.92 -347.04c-118.81,-19.1 -203.33,-124.37 -200.08,-241.62 4.89,1.43 9.9,2.58 15.05,3.41l2595.45 417.22c4.76,0.76 9.48,1.25 14.17,1.44z'
        />
        <path
          className='fil2'
          d='M11402.52 4886.33c1569.12,-718.34 3237.71,-1164.71 5007.38,-1292.63l-17.39 -108.18c-14.69,-91.61 -7.7,-163.23 13.79,-182.95 -1686.85,173.74 -3379.9,468.55 -5003.78,1084.19l0 499.57z'
        />
        <path
          className='fil1'
          d='M18910.35 2796.06l-2218.13 356.56c-61.01,9.8 -235.42,103.63 -285.92,149.95 -21.49,19.72 -28.48,91.34 -13.79,182.95l30.15 187.56c10.8,67.26 74.64,113.44 141.92,102.64l2595.5 -417.22c67.29,-10.83 113.45,-74.7 102.62,-141.95l-30.15 -187.56c-24.53,-152.67 -169.5,-257.44 -322.2,-232.93z'
        />
        <path
          className='fil2'
          d='M18949.92 2789.7l-2289.85 368.09c23.37,-47.38 181.18,-205.77 228.97,-213.45l1723.6 -277.07c66.74,-10.73 296.01,110.03 337.28,122.43z'
        />
        <path
          className='fil4'
          d='M16550.44 3777.15c33.71,112.3 146.92,185.68 265.67,166.59l2158.92 -347.04c118.81,-19.1 203.33,-124.37 200.08,-241.62 -4.89,1.43 -9.9,2.58 -15.05,3.41l-2595.45 417.22c-4.76,0.76 -9.48,1.25 -14.17,1.44z'
        />
      </g>
    </g>
  </svg>
);
export default SVGComponent;
