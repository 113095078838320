import { Column } from '@ant-design/plots';

export default function ColumnGraphics({ data }) {
  const config = {
    data,
    padding: 'auto',
    xField: 'date',
    yField: 'scales',
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    yAxis: {
      label: {
        formatter: (text) => `${text} kWh`,
      },
    },
    slider: {
      start: 0,
      end: 1,
    },
    minColumnWidth: 10,
    maxColumnWidth: 10,
  };

  return <Column {...config} />;
}
