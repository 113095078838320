import { useEffect, useState } from 'react';
import requets from '../../../api';
import { useKeycloak } from '@react-keycloak/web';
import moment from 'moment';

const loading = {
  LOADING: true,
  FINISHED: false,
};

export default function useModal(devEui) {
  const { keycloak } = useKeycloak();
  const req = requets(keycloak.token);
  const [params, setParams] = useState();
  const [dataSource, setDataSource] = useState({ energy: [], measurement: [] });
  const [loadingMeasurement, setLoadingMeasurement] = useState(
    loading.FINISHED
  );
  useEffect(() => {
    function fetchMeasurementByDevEui() {
      setLoadingMeasurement(loading.LOADING);
      req
        .get(`/measurement/${devEui}${params}`)
        .then((response) => {
          if (response.status === 200) {
            setDataSource(response.data);
          }
        })
        .finally(() => setLoadingMeasurement(loading.FINISHED));
    }

    if (!devEui || !params) return;

    fetchMeasurementByDevEui();
  }, [devEui, params]);
  return {
    loadingMeasurement,
    dataSource,
    setParams,
  };
}

function calculateConsumption(data) {
  const dataReducer = data.reduce((acc, item) => {
    const date = moment(item.formatt_date).format('YYYY-MM-DD');
    const energyAccumulated = item['energy_accumulated'];

    if (!acc[date]) {
      acc[date] = {
        date,
        startEnergy: energyAccumulated,
        endEnergy: energyAccumulated,
      };
    } else {
      acc[date].endEnergy = energyAccumulated;
    }

    return acc;
  }, {});

  const dailyConsumption = Object.keys(dataReducer).map((date) => {
    const { startEnergy, endEnergy } = dataReducer[date];
    const consumedEnergy = endEnergy - startEnergy;
    return { date, scales: consumedEnergy };
  });

  return Object.values(dailyConsumption);
}
