import { Card, Col, Row, Select, DatePicker, Form } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  setDevEui,
  setZone,
  setDate,
  setDepartment,
  setCounty,
} from "../../redux/slices/controlPanelSlice";
import requets from "../../api";
import { useKeycloak } from "@react-keycloak/web";

const { RangePicker } = DatePicker;

export default function ControlPanel() {
  const { keycloak } = useKeycloak();
  const req = requets(keycloak.token);
  const { t } = useTranslation("global");
  const dispatch = useDispatch();
  const [optionDepartament, setOptionDepartament] = useState([]);
  const [optionCounty, setOptionCounty] = useState([]);
  const [optionZone, setOptionZone] = useState([]);
  const [optionLamp, setOptionLamp] = useState([]);
  const [form] = Form.useForm();

  /**
   * Handles variables del Control panel
   * capturan el valor y almacenan el valor anterior de cada variable
   */
  const handleChangeDate = (date) => {
    if (date) {
      dispatch(
        setDate({ date: { start: date[0].valueOf(), end: date[1].valueOf() } })
      );
    }
  };

  const handleChangeDepartament = (department) => {
    req.get(`/county/department/${department}`).then((response) => {
      setOptionCounty(response.data);
    });
    dispatch(setDepartment({ department }))
    form.setFieldsValue({
      municipality: undefined,
      zone: undefined,
      lamp: undefined
    });
  };

  const handleChangeCounty = (county) => {
    req.get(`/zone/county/${county}`).then((response) => {
      setOptionZone(response.data);
    });
    dispatch(setCounty({ county }));
    form.setFieldsValue({
      zone: undefined,
      lamp: undefined
    });
  };

  const handleChangeZone = (zone) => {
    req.get(`/device/zone/${zone}`).then((response) => {
      setOptionLamp(response.data);
    });
    dispatch(setZone({ zone }));
    form.setFieldsValue({
      lamp: undefined
    });
  };

  const handleChangeLamp = (devEui) => {
    dispatch(setDevEui({ devEui }));
  };

  useEffect(() => {
    req.get('/department/state/?state=true').then(response => {
      setOptionDepartament(response.data);
    })
  }, []);

  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false}>
              <Form form={form}>
                <Row wrap="true" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={11} md={11} lg={4} span={6} className="row-space">
                    <Form.Item>
                      <RangePicker
                        size="large"
                        style={{width: '100%'}}
                        onChange={(date) => {
                          handleChangeDate(date)
                        }}
                        placeholder={[
                          t("controlPanel.startDate"),
                          t("controlPanel.endDate"),
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={11} md={11} lg={4} span={6} className="row-space">
                    <Form.Item name="departament">
                      <Select
                        size="large"
                        onChange={handleChangeDepartament}
                        options={optionDepartament}
                        placeholder={t("controlPanel.department")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={11} md={11} lg={4} span={6} className="row-space">
                    <Form.Item name="municipality">
                      <Select
                        size="large"
                        onChange={handleChangeCounty}
                        options={optionCounty}
                        placeholder={t("controlPanel.county")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={11} md={11} lg={4} span={6} className="row-space">
                    <Form.Item name="zone">
                      <Select
                        size="large"
                        onChange={handleChangeZone}
                        options={optionZone}
                        placeholder={t("controlPanel.zone")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={11} md={11} lg={4} span={6} className="row-space">
                    <Form.Item name="lamp">
                      <Select
                        size="large"
                        onChange={handleChangeLamp}
                        placeholder={t("controlPanel.lamp")}>
                        {optionLamp.map(device => (
                          <Select.Option key={device.devEui} value={device.devEui}>{device.name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}


