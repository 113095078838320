import { Button, Col, Form, Input, Row, Select, Switch } from "antd";
import { useTranslation } from "react-i18next";


export default function FormNotification(props) {

    const { t } = useTranslation("global");

    const itemSelect = [
        { value: 'email', label: 'Email' },
        { value: 'whatsapp', label: 'WhatsApp' }
    ];

    return (
        <>
            <Form layout="vertical" onFinish={props.onFinishForm} form={props.form}>
                <Row wrap="true" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={6} span={6} className="row-space">
                        <Form.Item
                            label={t('notification.channel')}
                            name="channel"
                            initialValue="email"
                            messageVariables={{ nameField: t("notification.channel") }}
                            rules={[
                                { required: true, message: t("formValidator.mandatory") },
                            ]}>
                            <Select
                                size="large"
                                placeholder={t('notification.channelPlaceholder')}
                                options={itemSelect}
                                onChange={props.onChange}>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} span={8} className="row-space">
                        <Form.Item
                            label={t(props.messageValue)}
                            name="value"
                            messageVariables={{ nameField: t(props.messageValue) }}
                            rules={[
                                {
                                    pattern: props.pattern,
                                    message: t(props.messageRules)
                                },
                                {
                                    required: true,
                                    message: t('formValidator.mandatory')
                                }
                            ]}>
                            <Input placeholder={t(props.messageValuePlaceHolder)} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={4} span={4} className="row-space">
                        <Form.Item
                            label={t('notification.state')}
                            name="state"
                            valuePropName="checked"
                            messageVariables={{ nameField: t("notification.state") }}
                            rules={[
                                { required: true, message: t("formValidator.mandatory") },
                            ]}>
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    {props.contextHolder}
                    <Button className="mb-2"  shape="round" htmlType="submit" type="primary">
                        {t('notification.save')}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}