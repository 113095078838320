import { Card, Layout, Row, Col, Tabs } from 'antd';
import { SearchBar } from './components/search';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useLamp from './hooks/hook-lamp';
import { ItemCol } from './components/item-col';
import { itemsTaps } from './components/taps';

const { Content } = Layout;
export default function Lamp() {
  const { data, device, setDevice, t } = useLamp();
  const { state } = useLocation();
  const [search, setSearch] = useState('');
  const [activeKey, setActiveKey] = useState('');

  useEffect(() => {
    if (data.length > 0) {
      const selectedDevice = state && state.device ? state.device : data[0];
      setDevice(selectedDevice);
      setActiveKey(selectedDevice.devEui);
    }
  }, [data, state]);

  const filterData = data.filter((item) => {
    return item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase());
  });

  const items = itemsTaps({t, device, data});

  const isActiveHandlerItem = (device) => {
    setDevice(device);
    setActiveKey(device.devEui);
  };
  
  return (
    <Content>
      <Card>
        <Row>
          <Col span={4} pull={0} xs={24} sm={24} md={6}>
            <SearchBar t={t} setSearch={setSearch} />
            <Row className='container-lamp' justify='start' gutter={[0, 0]}>
              {filterData.map((item, index) => (
                <ItemCol
                  key={index}
                  t={t}
                  item={item}
                  isActiveHandlerItem={isActiveHandlerItem}
                  isActive={item.devEui === activeKey}
                />
              ))}
            </Row>
          </Col>
          <Col span={16} push={1}>
            <Tabs defaultActiveKey='1' items={items} />
          </Col>
        </Row>
      </Card>
    </Content>
  );
}
