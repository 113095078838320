import { Modal, DatePicker, Card, Select, Row, Col, Table } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import useModal from '../hooks/hook-modal';
import PlotGraphics from '../../../context/share/PlotGraphics';
import ColumnGraphics from '../../../context/share/ColumnGraphics';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;
const currentDate = dayjs();
const startOfMonth = dayjs().startOf('month');

const pickerConfig = {
  RANGE: {
    Component: RangePicker,
    props: {
      defaultValue: [startOfMonth, currentDate],
      picker: undefined,
    },
  },
  YEAR: {
    Component: DatePicker,
    props: {
      defaultValue: currentDate,
      picker: 'year',
    },
  },
  MONTH: {
    Component: DatePicker,
    props: {
      defaultValue: currentDate,
      picker: 'month',
    },
  },
  DAY: {
    Component: DatePicker,
    props: {
      defaultValue: currentDate,
      picker: 'day',
    },
  },
};

const type = {
  RANGE: 'RANGE',
  YEAR: 'YEAR',
  MONTH: 'MONTH',
  DAY: 'DAY',
};

const fields = {
  'lamp.current': 'current',
  'lamp.factorPower': 'power_factor',
  'lamp.power': 'power',
  'lamp.voltage': 'voltage',
  'lamp.energy': 'energy_accumulated',
  'lamp.frequency': 'frequency',
};





export const ModalGraphics = ({ dataModal, setDataModal }) => {
  const { t } = useTranslation('global');
  const [typeReportGraphi, setTypeReportGraphi] = useState(type.MONTH);
  const [date, setDate] = useState(
    pickerConfig[typeReportGraphi]?.props.defaultValue
  );
  const { setParams, dataSource } = useModal(dataModal?.device?.devEui);
  const [data, setData] = useState([]);
  const [sumary, setSumary] = useState({ day: 0, month: 0, year: 0 });
  const today = moment().format('YYYY-MM-DD');

  useEffect(() => {
    if (!dataModal.title) return;

    if (typeReportGraphi && date) {
      setParams(queryParamsMap[typeReportGraphi](date));
    }

  },[dataModal, date, typeReportGraphi]);

  const options = [
    {
      label: t('lamp.range'),
      value: type.RANGE,
    },
    {
      label: t('lamp.diary'),
      value: type.DAY,
    },
    {
      label: t('lamp.month'),
      value: type.MONTH,
    },
    {
      label:  t('lamp.year'),
      value: type.YEAR,
    },
  ];

  useEffect(() => {

    if (!dataModal?.title && dataSource.length >= 0) return;

    if (typeReportGraphi && date) {
      
      if (dataModal?.title === 'lamp.energy') {
        let dataDay = dataSource.energy;
        
        let dataMonth = Object.values(
          dataDay.reduce((acc, item) => {
            const month = moment(item.date).format('YYYY-MM');
            if (!acc[month]) {
              acc[month] = { date: month, scales: 0 };
            }
            acc[month].scales += item.scales;
            return acc;
          }, {})
        );

        let dataYear = Object.values(
          Object.values(
            dataDay.reduce((acc, item) => {
              const month = moment(item.date).format('YYYY-MM');
              if (!acc[month]) {
                acc[month] = { date: month, scales: 0 };
              }
              acc[month].scales += item.scales;
              return acc;
            }, {})
          ).reduce((acc, item) => {
            const year = moment(item.date).format('YYYY');
            if (!acc[year]) {
              acc[year] = { date: year, scales: 0 };
            }
            acc[year].scales += item.scales;
            return acc;
          }, {})
        );

        setSumary({
          day: dataDay.reduce((total, item) => {
            if (item.date === today) {
              return total + item.scales;
            }
            return total;
          }, 0),
          month: dataMonth.reduce((total, item) => total + item.scales, 0),
          year: dataYear.reduce((total, item) => total + item.scales, 0),
        });

        if (typeReportGraphi === type.MONTH) {
          setData(dataMonth);
        } else if (typeReportGraphi === type.YEAR) {
          setData(dataYear);
        } else if (typeReportGraphi === type.RANGE) {
     
          setData(dataDay);
        } else {
          setData([
            dataDay.reduce(
              (acc, item) => {
                if (item.date === date.format('YYYY-MM-DD')) {
                  return {
                    date: item.date,
                    scales: acc.scales + item.scales,
                  };
                }
                return acc;
              },
              { date: date.format('YYYY-MM-DD'), scales: 0 }
            ),
          ]);
        }
      } else {
        setData(
          dataSource.measurement.map((item) => ({
            date: moment(item.formatt_date).format('YYYY-MM-DD HH:mm'),
            scales: item[fields[dataModal?.title]],
          }))
        );
      }
    }
  }, [dataModal, dataSource]);

  const queryParamsMap = {
    [type.RANGE]: (dates) => {
      const start = dates[0].startOf('day').valueOf();
      const end = dates[1].endOf('day').valueOf();
      return `?start=${start}&end=${end}`;
    },

    [type.YEAR]: (dates) => `?value_year=${dayjs(dates).format('YYYY')}`,

    [type.MONTH]: (dates) =>
      `?value_year=${dayjs(dates).format('YYYY')}&value_month=${dayjs(
        dates
      ).format('MM')}`,

    [type.DAY]: (dates) =>
      `?value_year=${dayjs(dates).format('YYYY')}&value_month=${dayjs(
        dates
      ).format('MM')}&value_day=${dayjs(dates).format('DD')}`,
  };

  const handleChangeDate = (date) => {
    setDate(date);
  };

  const handleChangeTypeGraphi = (value) => {
    setDate(pickerConfig[value]?.props.defaultValue);
    setTypeReportGraphi(value);
  };

  const columns = [
    {
      title: t('lamp.totalYear'),
      dataIndex: 'annual',
      key: 'annual',
      render: (value) => value?.toFixed(2) + ' kWh' || '0.00',
    },
    {
      title:  t('lamp.totalMonth'),
      dataIndex: 'monthly',
      key: 'monthly',
      render: (value) => value?.toFixed(2) + ' kWh' || '0.00',
    },
    {
      title: t('lamp.totalDiary'),
      dataIndex: 'daily',
      key: 'daily',
      render: (value) => value?.toFixed(2) + ' kWh' || '0.00',
    },
  ];

  const dataSumary = [
    {
      key: '1',
      annual: sumary?.year,
      monthly: sumary?.month,
      daily: sumary?.day,
    },
  ];

  return (
    <Modal
      maskClosable={false}
      title={dataModal.t(dataModal.title)}
      centered
      open={dataModal.open}
      onOk={() => setDataModal({ ...dataModal, open: !dataModal.open })}
      onCancel={() => setDataModal({ ...dataModal, open: !dataModal.open })}
      width={800}
    >
      <Card>
        <Row gutter={[16, 32]}>
          <Col span={24}>
            {dataModal?.title === 'lamp.energy' && (
              <Table
                columns={columns}
                dataSource={dataSumary}
                pagination={false}
              />
            )}
          </Col>
          <Col span={12}>
            <DatePickerControl
              typeReportGraphi={typeReportGraphi}
              handleChangeDate={handleChangeDate}
              t={dataModal.t}
            />
          </Col>
          <Col span={12}>
            <Select
              defaultValue={type.MONTH}
              onChange={handleChangeTypeGraphi}
              options={options}
              size='large'
              style={{
                width: '100%',
              }}
            />
          </Col>
        </Row>
      </Card>

      {dataModal.title === 'lamp.energy' ? (
        <ColumnGraphics data={data} />
      ) : (
        <PlotGraphics data={data} unit={dataModal?.unit} />
      )}
    </Modal>
  );
};

const DatePickerControl = ({ typeReportGraphi, handleChangeDate, t }) => {
  const { Component, props } = pickerConfig[typeReportGraphi] || {};

  if (!Component) return null;

  return (
    <Component
      size='large'
      style={{ width: '100%' }}
      onChange={handleChangeDate}
      placeholder={[
        t('controlPanel.startDate'),
        typeReportGraphi === 'RANGE' && t('controlPanel.endDate'),
      ].filter(Boolean)}
      {...props}
    />
  );
};

export function calculateConsumption(data) {
  const dataReducer = data.reduce((acc, item) => {
    const date = moment(item.formatt_date).format('YYYY-MM-DD');
    const energyAccumulated = item['energy_accumulated'];

    if (!acc[date]) {
      acc[date] = {
        date,
        startEnergy: energyAccumulated,
        endEnergy: energyAccumulated,
      };
    } else {
      acc[date].endEnergy = energyAccumulated;
    }

    return acc;
  }, {});

  const dailyConsumption = Object.keys(dataReducer).map((date) => {
    const { startEnergy, endEnergy } = dataReducer[date];
    const consumedEnergy = endEnergy - startEnergy;
    return { date, scales: consumedEnergy };
  });

  return Object.values(dailyConsumption);
}
