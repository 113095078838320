import { Col, Layout, Row, Space, Table, Pagination, Spin } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import requets from "../../api";
import columnAlert from "./table/data.table";
import { useKeycloak } from "@react-keycloak/web";
import ReportExcel from "../../api/service/report/reportExcel";
import { ReportCsv } from "../../api/service/report/reportCsv";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
const { Content } = Layout;

export default function Alert() {
  const { keycloak } = useKeycloak();
  const controlPanel = useSelector((state) => state.controlPanel);
  const { t } = useTranslation("global");
  const req = requets(keycloak.token);
  const [data, setDataSource] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [spinning, setSpinning] = useState(false);

  useEffect(() => {
    setSpinning(true);
    const { date, department, county, zone, devEui } = controlPanel;

    req.get("/history/count", {
      ...date,
      department,
      county,
      zone,
      devEui,
    }).then((response) => {
      setCount(response.data);
    });

    req.get("/history", {
      ...date,
      department,
      county,
      zone,
      devEui,
      page: currentPage,
      pageSize
    }).then((response) => {
      setDataSource(response.data);
    }).finally(() => setSpinning(false));

  }, [controlPanel, currentPage, pageSize]);

  const changePages = (current, pageSizePaginate) => {
    if (isNaN(pageSizePaginate)) {
      pageSizePaginate = count;
    }
    setCurrentPage(current);
    setPageSize(pageSizePaginate);
  }

  const onShowSizeChange = async (current, pageSizePaginate) => {

    if (isNaN(pageSizePaginate)) {
      pageSizePaginate = count;
    }
    await setCurrentPage(current);
    await setPageSize(pageSizePaginate);
  };

  const totalPage = () => {
    return `Total ${count}`;
  }

  const translate = (data) => {

    const reports = data.map((alert) => {

      let response = {
        date: moment(parseInt(alert.date)).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        status: alert.status,
        state: alert.state,
        description: alert.description,
      };

      const objet = {};

      for (const key in response) {
        const keyTranslate = t(`alerts.tableColumn.${key}`);
        objet[`${keyTranslate}`] = response[key];
      }

      return objet;

    });
    return reports;
  }

  return (
    <>
      <Spin spinning={spinning} fullscreen="true" tip={t('loading')}>
        <Content>
          <Row justify="end">
            <Col>
              <Space>

                <ReportExcel
                  dataSet={translate(data)}
                  fileName="reporte-alert"
                ></ReportExcel>
                <ReportCsv
                  dataSet={translate(data)}
                  fileName="reporte-alert"
                ></ReportCsv>
              </Space>
            </Col>
          </Row>
          <Table
            columns={columnAlert()}
            dataSource={data}
            rowKey="uuid"
            scroll={{ x: "calc(700px + 50%)" }}
            pagination={false}
          ></Table>
          <Pagination
            defaultCurrent={1}
            current={currentPage}
            pageSize={pageSize}
            total={count}
            onChange={changePages}
            showTotal={totalPage}
            showSizeChanger
            pageSizeOptions={['10', '20', '50', '100', 'Todos']}
            onShowSizeChange={onShowSizeChange} />
        </Content>
      </Spin>
    </>
  );
}
