import { useTranslation } from "react-i18next";
import { Button, Radio, Space, Switch, Tag } from "antd";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import SearchDataIndex from "../../../context/share/SearchDataIndex";
import { useNavigate } from 'react-router-dom';

export default function Column(
    handleDeleteEvent,
    onChange,
    handlerDimerization,
    handlerMessage) {

    const getDataIndex = SearchDataIndex();
    const { t } = useTranslation("global");
    const navigate = useNavigate();

    const column =
        [
            {
                title: t("device.tableColumn.status"),
                dataIndex: "status",
                key: "status",
                render: (_, record) => (
                    <Button type="text" onClick={() => navigate('/lamp/', { state: { device: record }})}>
                        {t('device.open')}
                    </Button>
                ),
            },
            {
                title: t("device.tableColumn.control"),
                dataIndex: "devEui",
                key: "controls",
                render: (_, record) => (
                    <Space>
                        <Switch defaultChecked={record.state === 'ON'} checkedChildren="ON" unCheckedChildren="OFF" onChange={(checked) => onChange(checked, record)} />
                        <div className="container__dimerization-slider">
                            <Radio.Group defaultValue={record.dimerization} buttonStyle="solid" onChange={(checked) => handlerDimerization(checked, record)}>
                                <Radio.Button value="ALTO">{t('lamp.high')}</Radio.Button>
                                <Radio.Button value="MEDIO">{t('lamp.half')}</Radio.Button>
                                <Radio.Button value="INTERM">{t('lamp.middle')}</Radio.Button>
                                <Radio.Button value="BAJA">{t('lamp.low')}</Radio.Button>
                            </Radio.Group>
                        </div>
                    </Space>
                ),
            },
            {
                title: t("device.tableColumn.message"),
                dataIndex: "devEui",
                key: "message",
                render: (_, record) => (
                    <Space>
                        <div className="container__dimerization-slider">
                            <Radio.Group
                                defaultValue={record.message}
                                buttonStyle="solid"
                                onChange={(checked) => handlerMessage(checked, record)}
                            >
                                <Radio.Button value="T=1M">{t("lamp.tm1")}</Radio.Button>
                                <Radio.Button value="T=3M">{t("lamp.tm3")}</Radio.Button>
                                <Radio.Button value="T=5M">{t("lamp.tm5")}</Radio.Button>
                                <Radio.Button value="T=15M">{t("lamp.tm15")}</Radio.Button>
                            </Radio.Group>
                        </div>
                    </Space>
                ),
            },
            { title: t("device.tableColumn.nameLamp"), dataIndex: "name", key: "name", ...getDataIndex('name') },
            { title: t("device.tableColumn.marquille"), dataIndex: "marquille", key: "marquille", ...getDataIndex('marquille') },
            { title: t("device.tableColumn.brand"), dataIndex: "brand", key: "brand" },
            { title: t("device.tableColumn.eui"), dataIndex: "devEui", key: "deveui" },
            {
                title: t("device.tableColumn.state"),
                dataIndex: "state",
                key: "state",
                render: (_, record) => {
                    let color = record.status === 'OK' ? 'green' : 'red';
                    let state = record.status === 'OK' ? 'device.success' : 'device.failed';
                    return (
                        <Tag color={color} key={record.state}>
                            {t(state)}
                        </Tag>
                    );
                },
            },
            {
                title: t("device.tableColumn.option"),
                dataIndex: "devEui",
                key: "option",
                render: (_, record) => (
                    <Space direction="vertical">
                        <Space>
                            <Button type="text" icon={<EditOutlined />} href={`/device/${record.devEui}/edit`}>
                                {t('device.edit')}
                            </Button>
                            <Button type="text" icon={<DeleteOutlined size={'large'} />} onClick={() => handleDeleteEvent(record.devEui)}>
                                {t('device.delete')}
                            </Button>
                        </Space>
                    </Space>
                ),
            }
        ];

    return column;

}
