import { Col, Badge } from 'antd';
const marginRight = { marginRight: '2rem' };
export const ItemCol = ({ item, t, isActiveHandlerItem, isActive }) => {
  return (
    <Col
      key={item.devEui}
      onClick={() => isActiveHandlerItem(item)}
      className={`item-lamp ${isActive ? 'item-lamp-active' : ''}`}
      span={24}
    >
      <div className='item-col-lamp'>
        {item.name}
        <Badge
          style={marginRight}
          status={item.state === 'ON' ? 'success' : 'error'}
          text={
            item.state === 'ON'
              ? t('reports.tableColumn.stateValueOn')
              : t('reports.tableColumn.stateValueOff')
          }
        />
      </div>
    </Col>
  );
};
