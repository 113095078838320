import { Row, Table, Col, DatePicker, Card, Select, Space, Tag } from 'antd';
import PlotGraphics from '../../../context/share/PlotGraphics';
import ColumnGraphics from '../../../context/share/ColumnGraphics';
import moment from 'moment';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useGraphics from '../hooks/hook-graphics';
import { useTranslation } from 'react-i18next';

const type = {
  RANGE: 'RANGE',
  YEAR: 'YEAR',
  MONTH: 'MONTH',
  DAY: 'DAY',
};



const currentDate = dayjs();

const pickerConfig = {
  YEAR: {
    Component: DatePicker,
    props: {
      defaultValue: currentDate,
      picker: 'year',
    },
  },
  MONTH: {
    Component: DatePicker,
    props: {
      defaultValue: currentDate,
      picker: 'month',
    },
  },
  DAY: {
    Component: DatePicker,
    props: {
      defaultValue: currentDate,
      picker: 'day',
    },
  },
};

export const Graphics = ({ data: listDevices }) => {
  const { t } = useTranslation('global');
  const { measurement, setParams } = useGraphics({
    list: Object.values(listDevices.map((item) => item.devEui)),
  });
  const controlPanel = useSelector((state) => state.controlPanel);
  const [date, setDate] = useState(currentDate.format('YYYY-MM-DD'));
  const [typeReportGraphi, setTypeReportGraphi] = useState(type.MONTH);

  const options = [
    {
      label: t('lamp.range'),
      value: type.RANGE,
    },
    {
      label: t('lamp.diary'),
      value: type.DAY,
    },
    {
      label: t('lamp.month'),
      value: type.MONTH,
    },
    {
      label:  t('lamp.year'),
      value: type.YEAR,
    },
  ];

  useEffect(() => {
    if (typeReportGraphi === type.RANGE && controlPanel) {
      if (controlPanel.date) {
        const { start, end } = controlPanel.date;
        setParams(
          queryParamsMap[type.RANGE]([
            moment(start).startOf('day').valueOf(),
            moment(end).endOf('day').valueOf(),
          ])
        );
      } else {
        setParams(
          queryParamsMap[type.RANGE]([
            moment().startOf('month').valueOf(),
            moment().endOf('month').valueOf(),
          ])
        );
      }
    } else {
      setParams(queryParamsMap[typeReportGraphi](date));
    }
  }, [controlPanel, typeReportGraphi, listDevices, date]);

  const queryParamsMap = {
    [type.RANGE]: (dates) => {
      const start = dates[0];
      const end = dates[1];
      return { start, end };
    },

    [type.YEAR]: (dates) => {
      const value_year = dayjs(dates).format('YYYY');
      return { value_year };
    },

    [type.MONTH]: (dates) => {
      const value_year = dayjs(dates).format('YYYY');
      const value_month = dayjs(dates).format('MM');
      return { value_year, value_month };
    },

    [type.DAY]: (dates) => {
      const value_year = dayjs(dates).format('YYYY');
      const value_month = dayjs(dates).format('MM');
      const value_day = dayjs(dates).format('DD');
      return { value_year, value_month, value_day };
    },
  };

  const handleChangeTypeGraphi = (value) => {
    setTypeReportGraphi(value);
  };

  const handleChangeDate = (date) => {
    setDate(date);
  };

  const datMonth = Object.values(
    measurement?.energy.reduce((acc, item) => {
      const month = moment(item.date).format('YYYY-MM');
      if (!acc[month]) {
        acc[month] = { date: month, scales: 0 };
      }
      acc[month].scales += item.scales;
      return acc;
    }, {})
  );

  const dataYear = Object.values(
    Object.values(
      measurement?.energy.reduce((acc, item) => {
        const month = moment(item.date).format('YYYY-MM');
        if (!acc[month]) {
          acc[month] = { date: month, scales: 0 };
        }
        acc[month].scales += item.scales;
        return acc;
      }, {})
    ).reduce((acc, item) => {
      const year = moment(item.date).format('YYYY');
      if (!acc[year]) {
        acc[year] = { date: year, scales: 0 };
      }
      acc[year].scales += item.scales;
      return acc;
    }, {})
  );

  const dataDay = measurement?.energy.reduce(
    (acc, item) => {
      return {
        date: moment(item.date).format('YYYY-MM-DD'),
        scales: acc.scales + item.scales,
      };
    },
    { date: date, scales: 0 }
  );

  const columns = [
    {
      title: t('lamp.total'),
      dataIndex: 'total',
      key: 'total',
      render: (value) => <Tag color='processing'>{value?.toFixed(2) + ' kWh' || '0.00'}</Tag>,
    },
    {
      title: t('lamp.totalToday'),
      dataIndex: 'today',
      key: 'today',
      render: (value) => <Tag color='processing'>{value?.toFixed(2) + ' kWh' || '0.00'}</Tag>,
    },
  ];

  const dataSumary = [
    {
      key: '1',
      total: dataYear.reduce((total, item) => total + item.scales, 0),
      today: measurement?.energy.reduce((total, item) => {
        if (
          moment(item.date).format('YYYY-MM-DD') ===
          currentDate.format('YYYY-MM-DD')
        ) {
          return total + item.scales;
        }
        return total;
      }, 0),
    },
  ];

  return (
    <Row gutter={[24, 16]}>
      <Col span={24}>
        <Table columns={columns} dataSource={dataSumary} pagination={false} />
        <Card
          title={t('config.energy')}
          extra={
            <Space>
              <DatePickerControl
                typeReportGraphi={typeReportGraphi}
                handleChangeDate={handleChangeDate}
                t={t}
              />
              <Select
                defaultValue={type.MONTH}
                onChange={handleChangeTypeGraphi}
                options={options}
                size='large'
                style={{
                  width: '100%',
                }}
              />
            </Space>
          }
        >
          {typeReportGraphi === type.MONTH && (
            <ColumnGraphics data={datMonth} unit={'A'} />
          )}

          {typeReportGraphi === type.YEAR && (
            <ColumnGraphics data={dataYear} unit={'A'} />
          )}

          {typeReportGraphi === type.DAY && (
            <ColumnGraphics data={[dataDay]} unit={'A'} />
          )}

          {typeReportGraphi === type.RANGE && (
            <ColumnGraphics data={measurement?.energy} unit={'A'} />
          )}
        </Card>
      </Col>
      <Col span={24}>
        <Card title={t('config.energy')}>
          <PlotGraphics data={measurement.measurement} multiple={true} />
        </Card>
      </Col>
    </Row>
  );
};

const DatePickerControl = ({ typeReportGraphi, handleChangeDate, t }) => {
  const { Component, props } = pickerConfig[typeReportGraphi] || {};

  if (!Component) return null;

  return (
    <Component
      size='large'
      style={{ width: '100%' }}
      onChange={handleChangeDate}
      placeholder={[
        t('controlPanel.startDate'),
        typeReportGraphi === 'RANGE' && t('controlPanel.endDate'),
      ].filter(Boolean)}
      {...props}
    />
  );
};
