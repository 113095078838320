import "antd/dist/reset.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import "./assets/styles/style.css";
import "./App.css";
import { ConfigProvider } from "antd";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import AuthRoutes from "./routes/authRoutes.js";
import keycloakAuth from "./api/service/auth.js";
import { Provider } from "react-redux";
import { store } from "./redux/store";
function App() {
  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#0000fd",
          },
          components: {
            Table: {
              colorFillAlter: "rgba(0, 0, 0, 0.06)",
              fontWeightStrong: 700
            }
          }
        }}
      >
        <div className="App">
          <ReactKeycloakProvider authClient={keycloakAuth}>
            <AuthRoutes />
          </ReactKeycloakProvider>
        </div>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
