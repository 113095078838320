import { Button, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import useMeasurement from '../hooks/hook-measurement';
import { useEffect, useState } from 'react';
import { ModalGraphics } from './modal';
import moment from 'moment';
import { TableDevice } from './tableDevice';
export const Measurement = ({ device }) => {
  const { t } = useTranslation('global');
  const [dataTable, setdataTable] = useState([]);
  const { lastMeasurement } = useMeasurement(device?.devEui);
  const [dataModal, setDataModal] = useState({
    open: false,
    title: '',
    t,
    device,
    unit: '',
  });

  useEffect(() => {
    setDataModal({ ...dataModal, device });
    setdataTable([
      {
        key: '1',
        name: device?.name,
        marquille: device?.marquille,
        power: `${device?.power} W`,
        state: device?.state,
        lastMeasurementDate: moment(lastMeasurement?.formatt_date).format(
          'YYYY-MM-DD HH:mm A'
        ),
      },
    ]);
  }, [device, lastMeasurement]);

  return (
    <div>
      <TableDevice t={t} dataTable={dataTable} />
      <Row justify='start' gutter={{ xs: 8, sm: 16, md: 18, lg: 32 }}>
        <ItemCardMeasurement
          t={t}
          title='lamp.current'
          unit='A'
          value={lastMeasurement?.current?.value}
          status={lastMeasurement?.current?.status}
          isOpenModal={() =>
            setDataModal({
              ...dataModal,
              open: !dataModal.open,
              title: 'lamp.current',
              unit: 'A',
            })
          }
        />
        <ItemCardMeasurement
          t={t}
          title='lamp.factorPower'
          unit=''
          value={lastMeasurement?.power_factor?.value}
          status={lastMeasurement?.power_factor?.status}
          isOpenModal={() =>
            setDataModal({
              ...dataModal,
              open: !dataModal.open,
              title: 'lamp.factorPower',
              unit: '',
            })
          }
        />
        <ItemCardMeasurement
          t={t}
          title='lamp.power'
          unit='W'
          value={lastMeasurement?.power?.value}
          status={lastMeasurement?.power?.status}
          isOpenModal={() =>
            setDataModal({
              ...dataModal,
              open: !dataModal.open,
              title: 'lamp.power',
              unit: 'W',
            })
          }
        />
        <ItemCardMeasurement
          t={t}
          title='lamp.voltage'
          unit='V'
          value={lastMeasurement?.voltage?.value}
          status={lastMeasurement?.voltage?.status}
          isOpenModal={() =>
            setDataModal({
              ...dataModal,
              open: !dataModal.open,
              title: 'lamp.voltage',
              unit: 'V',
            })
          }
        />
        <ItemCardMeasurement
          t={t}
          title='lamp.energy'
          unit='kWh'
          value={lastMeasurement?.energy?.value}
          status={lastMeasurement?.energy?.status}
          isOpenModal={() =>
            setDataModal({
              ...dataModal,
              open: !dataModal.open,
              title: 'lamp.energy',
              unit: 'KWh',
            })
          }
        />
        <ItemCardMeasurement
          t={t}
          title='lamp.frequency'
          unit='Hz'
          value={lastMeasurement?.frequency?.value}
          status={lastMeasurement?.frequency?.status}
        />
      </Row>
      <ModalGraphics dataModal={dataModal} setDataModal={setDataModal} />
    </div>
  );
};

const ItemCardMeasurement = ({
  t,
  title,
  unit,
  value,
  status,
  isOpenModal,
}) => {
  const color = status === 'FAIL' ? { color: '#ffab66' } : { color: '#39C337' };
  return (
    <Col span={5} xs={24} sm={24} md={12} lg={8}>
      <div className='item-measurements'>
        <h3>{t(title)}</h3>
        <p>
          {value} {unit}
        </p>
        <p style={color}>{status === 'FAIL' ? t('lamp.outOfRange')  : 'OK' }</p>
        <div>
          <Button type='primary' shape='round' onClick={isOpenModal}>
            {t('lamp.showMore')}
          </Button>
        </div>
      </div>
    </Col>
  );
};
