import axios from "axios";
import { server } from "./service/server/axios";

const requets = (token) => {
  const axiosInstance = axios.create({
    baseURL: server(),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const req = {
    get: async (url, params) => await axiosInstance.get(url, { params }),
    post: async (url, data, params = null) => await axiosInstance.post(url, data, { params }),
    put: async (url, data) => await axiosInstance.put(url, data),
    patch: async (url, data) => await axiosInstance.patch(url, data),
    delete: async (url) => await axiosInstance.delete(url),
    postFile: async (url, data, option) => await axiosInstance.post(url, data, option),
  };

  return req;
};

export default requets;
