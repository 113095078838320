import { Button, Card, Form, Switch, List, message } from "antd";
import Layout from "antd/es/layout/layout";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import requets from "../../api";
import { useEffect, useState } from "react";
import FormNotification from "./components/form.notification";
import { capitalizeFirstLetter } from "../../context/share/Utils";
import { useKeycloak } from "@react-keycloak/web";
const { Content } = Layout;

export default function Notification() {
  const { keycloak } = useKeycloak();
  const req = requets(keycloak.token);
  const { t } = useTranslation("global");

  const [messageSend, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  
  const [notification, setNotification] = useState([]);
  const [uuid, setUuid] = useState(null);
  const [pattern, setPattern] = useState(
    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  );
  const [messageValue, setMessageValue] = useState("notification.email");
  const [messageValuePlaceHolder, setMessageValuePlaceHolder] = useState(
    "notification.emailPlaceholder"
  );
  const [messageRules, setMessageRules] = useState("formValidator.email");

  const onChange = (value) => {
    setPattern(
      value === "email"
        ? /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
        : /^(?:\+\d{1,3})?\d{6,}$/
    );
    setMessageValue(
      value === "email" ? "notification.email" : "notification.whatsapp"
    );
    setMessageValuePlaceHolder(
      value === "email"
        ? "notification.emailPlaceholder"
        : "notification.whatsappPlaceholder"
    );
    setMessageRules(
      value === "email" ? "formValidator.email" : "formValidator.phone"
    );

    form.setFieldsValue({ value: '' })
  };

  const getNotifications = () => {
    req.get("/notification/").then((response) => {
      setNotification(response.data);
    });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const onChangeSwitch = (key, checked) => {
    req.put(`/notification/${key}`, { state: checked }).then((response) => {
      if (response.status === 200) {
        messageSend.open({
          type: "success",
          content: t("notification.notificationUpdated"),
        });
        form.resetFields();

        const notificationIndex = notification.findIndex(
          (item) => item.uuid === key
        );

        if (notificationIndex !== -1) {
          const updatedNotification = [...notification];

          updatedNotification[notificationIndex].state = checked;

          setNotification(updatedNotification);
        }
      }
    });
  };

  const handlerEditEvent = (key) => {
    req.get(`/notification/${key}`).then((response) => {
      if (response.status === 200) {
        setUuid(response.data.uuid);

        delete response.data.uuid;

        onChange(response.data.channel);

        form.setFieldsValue(response.data);
      }
    });
  };

  const handlerDeleteEvent = (key) => {
    req.delete(`/notification/${key}`).then((response) => {
      if (response.status === 200) {
        getNotifications();
        messageSend.open({
          type: "success",
          content: t("notification.notificationDeleted"),
        });
      }
    });
  };

  const postNotification = (value) => {
    req.post("/notification/", value).then((response) => {
      if (response.status === 201) {
        getNotifications();
        messageSend.open({
          type: "success",
          content: t("notification.setting"),
        });

        form.resetFields();
      }
    });
  };

  const updateNotification = (value) => {
    req.put(`/notification/${uuid}`, value).then((response) => {
      if (response.status === 200) {
        messageSend.open({
          type: "success",
          content: t("notification.notificationUpdated"),
        });

        getNotifications();
        form.resetFields();

        setUuid(null);
      }
    });
  };

  const onFinish = (value) => {
    uuid ? updateNotification(value) : postNotification(value);
  };

  return (
    <Content>
      <Card style={{}}>
        <FormNotification
          form={form}
          onFinishForm={onFinish}
          contextHolder={contextHolder}
          pattern={pattern}
          messageValue={messageValue}
          messageValuePlaceHolder={messageValuePlaceHolder}
          messageRules={messageRules}
          onChange={onChange}
        />
        <List
          bordered
          dataSource={notification}
          itemLayout="horizontal"
          style={{ overflowX: 'auto'}}
          renderItem={(item) => (
            <List.Item
            key={item.uuid}
            style={{ minWidth: '600px'}}
              actions={[
                <Switch
                  checked={item.state}
                  onChange={(checked) => onChangeSwitch(item.uuid, checked)}
                />,
                <Button
                  type="text"
                  icon={<EditOutlined size={"large"} />}
                  onClick={() => handlerEditEvent(item.uuid)}
                >
                  {t("notification.edit")}
                </Button>,
                <Button
                  type="text"
                  icon={<DeleteOutlined size={"large"} />}
                  onClick={() => handlerDeleteEvent(item.uuid)}
                >
                  {t("notification.delete")}
                </Button>,
              ]}
            >
              <List.Item.Meta
                title={item.value}
                description={capitalizeFirstLetter(item.channel)}
              />
              
            </List.Item>
          )}
        ></List>
      </Card>
    </Content>
  );
}
