import { Button, Col, Form, Input, Row, List, Space, message } from 'antd';
import { useTranslation } from 'react-i18next';
import requets from '../../../api';
import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';

export default function CodeForm(props) {
  const { t } = useTranslation('global');
  const { keycloak } = useKeycloak();
  const [messageSend, contextHolder] = message.useMessage();
  const req = requets(keycloak.token);
  const [form] = Form.useForm();

  const [codes, setCodes] = useState([]);
  const [uuidCode, setUuidCode] = useState('');

  const onFinish = ({ label }) => {
    uuidCode ? updateCodeForm(label) : createCode(label);
  };

  const createCode = (label) => {
    req.post(`/code/`, { label }).then((response) => {
      if (response.status === 201) {
        messageSend.open({
          type: 'success',
          content: t('code.sucessCreatedCode'),
        });
        form.resetFields();
        fetchCode();
      }
    });
  };

  const onUpdateCode = (value, label) => {
    form.setFieldValue('label', label);
    setUuidCode(value);
  };

  const updateCodeForm = (label) => {
    req.put(`/code/${uuidCode}`, { label }).then((response) => {
      if (response.status === 200) {
        messageSend.open({
          type: 'success',
          content: t('code.sucessUpdateCode'),
        });

        fetchCode();
        form.resetFields();
      }
    });
  };

  const onUpdateDelete = (value) => {
    req.delete(`/code/${value}`).then((response) => {
        if (response.status === 200) {
            messageSend.open({
              type: 'success',
              content: t('code.sucessDeleteCode'),
            });
    
            fetchCode();
          }
    })
  };

  const fetchCode = () => {
    req.get('/code/').then((response) => {
      setCodes(response.data);
    });
  };

  useEffect(() => {
    fetchCode();
  }, []);

  return (
    <div className='code-container'>
      <div>
        <Form layout='vertical' onFinish={onFinish} form={form}>
          <Row wrap='true' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={4} span={4} className='row-space'>
              <Form.Item
                label={t('code.code')}
                name='label'
                messageVariables={{ nameField: t('code.code') }}
                rules={[
                  { required: true, message: t('formValidator.mandatory') },
                ]}
              >
                <Input placeholder={t('code.codePlaceHolder')} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            {contextHolder}
            <Button
              shape='round'
              className='mb-2'
              htmlType='submit'
              type='primary'
            >
              {t('deviceEdit.saveChange')}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div>
      {contextHolder}
        <List
          dataSource={codes}
          renderItem={(item) => (
            
            <List.Item key={item.value}>
              <List.Item.Meta title={item.label}></List.Item.Meta>
              <Space>
                <Button onClick={() => onUpdateCode(item.value, item.label)}>
                  {t('code.edit')}
                </Button>
                <Button onClick={() => onUpdateDelete(item.value)}>
                    {t('code.delete')}
                </Button>
              </Space>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}
