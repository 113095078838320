import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Form,
  Layout,
  Row,
  Space,
  Table,
  Tabs,
  message,
} from "antd";
import { useTranslation } from "react-i18next";
import columnZone from "./table/data.table";
import FormEdit from "./components/form.edit";
import Department from "./components/deparment";
import requets from "../../api";
import { useKeycloak } from "@react-keycloak/web";
import ReportExcel from "../../api/service/report/reportExcel";
import { ReportCsv } from "../../api/service/report/reportCsv";
import CodeForm from "./components/code";

const { Content } = Layout;

export default function Zone() {
  const { keycloak } = useKeycloak();
  const req = requets(keycloak.token);
  const { t } = useTranslation("global");
  const [messageSend, contextHolder] = message.useMessage();
  const [uuid, setUuid] = useState("");
  const [data, setDataSource] = useState([]);
  const [departament, setDepartament] = useState([]);
  const [departamentWithState, setDepartamentWithState] = useState([]);
  const [counties, setCounties] = useState([]);
  const [form] = Form.useForm();
  const [spinning, setSpinning] = useState(false);
  const showLoader = () => {
    setSpinning(true);
    setTimeout(() => {
      setSpinning(false);
    }, 10000);
  };
  const getDepartmentWithState = () => {
    req.get("/department/state/?state=true").then((response) => {
      setDepartamentWithState(response.data);
    });
  };
  const controlDevice = (uuidZone, action) => {
    req
      .post(`/device/control/zone/${uuidZone}`, { action: action })
      .then((response) => {
        if (response.status !== 200) {
          messageSend.open({
            type: "error",
            content: t("deviceEdit.error"),
          });
        }
      });
  };

  const getZones = () => {
    req.get("/zone/").then((response) => {
      setDataSource(response.data);
    });
  };

  const onChangeZonesTable = () => {
    getZones();
  };
  const onChange = (checked, device) => {
    const action = checked ? "R-ON" : "R-OFF";
    controlDevice(device.uuid, action);
    showLoader();
  };
  const handlerDimerization = (checked, device) => {
    const value = checked.target.value;
    controlDevice(device.uuid, value);
    showLoader();
  };
  const handlerMessage = (checked, device) => {
    const value = checked.target.value;
    controlDevice(device.uuid, value);
    showLoader();
  };
  const handleSwitchMode = (checked, device) => {
    const value = checked ? 'MANUAL' : 'AUTO';
    controlDevice(device.uuid, value);
    showLoader();
  };

  const onChangeDepartament = async (value) => {
    await req.get(`/county/department/${value}`).then((response) => {
      form.setFieldsValue({
        fkCountyUuid: undefined,
      });
      setCounties(response.data);
    });
  };

  useEffect(() => {
    req.get("/department/").then((response) => {
      setDepartament(response.data);
    });

    getDepartmentWithState();
    getZones();
  }, []);

  const items = [
    {
      key: "1",
      label: t("zones.department"),
      children: (
        <Department
          departament={departament}
          refreshDepartment={getDepartmentWithState}
        />
      ),
    },
    {
      key: "2",
      label: t("zones.zone"),
      children: (
        <>
          <FormEdit
            form={form}
            onChangeZonesTable={onChangeZonesTable}
            onChangeDepartament={onChangeDepartament}
            departament={departamentWithState}
            counties={counties}
            uuid={uuid}
            setUuid={setUuid}
          />
          {console.log(uuid)}
          <Row justify="end">
            <Col>
              <Space>
                <ReportExcel
                  dataSet={data}
                  fileName="reporte-alerta"
                ></ReportExcel>
                <ReportCsv dataSet={data}></ReportCsv>
              </Space>
            </Col>
          </Row>
          {contextHolder}
          <Table
            columns={columnZone(
              getZones,
              form,
              setUuid,
              onChange,
              onChangeDepartament,
              handlerDimerization,
              handlerMessage,
              handleSwitchMode
            )}
            dataSource={data}
            scroll={{ x: "calc(700px + 50%)" }}
            rowKey="uuid"
          ></Table>
        </>
      ),
    },
    {
      key: "3",
      label: t("code.code"),
      children: <CodeForm />,
    },
  ];

  return (
    <Content>
      <Card bordered={false}>
        <Tabs defaultActiveKey="1" items={items} />
      </Card>
    </Content>
  );
}
