import { useEffect, useState } from 'react';
import {
  Card,
  Layout,
  Tabs,
  message,
  Form,
  Row,
  Col,
  Input,
  Button,
  Select,
  Switch,
  DatePicker,
} from 'antd';
import requets from '../../api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from 'moment/moment';
dayjs.extend(customParseFormat);
const { Content } = Layout;

export default function DeviceEdit() {
  const { keycloak } = useKeycloak();

  const req = requets(keycloak.token);

  const { devEui } = useParams();
  const [zones, setZones] = useState([]);
  const [code, setCode] = useState([]);
  const [dates, setDates] = useState({
    installationDate: null,
    manufacturingDate: null,
  });
  const [marquilla, setMarquilla] = useState([]);
  const { t } = useTranslation('global');

  const [form] = Form.useForm();

  const [messageSend, contextHolder] = message.useMessage();

  const [device, setDevice] = useState({});

  

  useEffect(() => {
    req.get('/zone').then((response) => {
      setZones(response.data);
    });

    req.get(`/device/${devEui}`).then((response) => {
      const rta = response.data;
      rta.latitud = response.data.location[0];
      rta.longitud = response.data.location[1];

      setDates({
        installationDate: rta.installationDate,
        manufacturingDate: rta.manufacturingDate
      })
      form.setFieldValue('marquille', rta.marquille);
      setDevice(rta);
    });

    req.get('/code').then((response) => {
      setCode(response.data);
    });
  }, [devEui]);

  useEffect(() => {
    formSetMarquilla();
  }, [marquilla]);

  const onFinish = (value) => {
    value.location = [value.latitud, value.longitud];
    delete value.latitud;
    delete value.longitud;

    value.installationDate = dates.installationDate;
    value.manufacturingDate = dates.manufacturingDate;

    req.put(`/device/${devEui}`, value).then((response) => {
      if (response.status === 200) {
        messageSend.open({
          type: 'success',
          content: t('deviceEdit.sucessUpdated'),
        });
      }
    });
  };

  const validNumber = (value) => {
    if (!isNaN(value)) {
      return parseFloat(value);
    }
    return value;
  };

  const onChangeCode = (value) => {
    setMarquilla([value, ...marquilla.slice(1)]);
  };

  const onChangeZone = (value, record) => {
    const updatedValues =
      marquilla.length >= 2
        ? [marquilla[0], record.code, ...marquilla.slice(2)]
        : [marquilla[0], record.code];
    setMarquilla(updatedValues);
  };

  const handleInputChange = (e) => {
    if (marquilla.length >= 2) {
      setMarquilla([marquilla[0], marquilla[1], e.target.value]);
    } else {
      setMarquilla([...marquilla, e.target.value]);
    }
  };

  const onChangInstallationDate = (date, dateString) => {
    setDates({
      ...dates,
      installationDate: dateString,
    })
  };

  const onChangManufacturingDate = (date, dateString) => {
    setDates({
      ...dates,
      manufacturingDate: dateString,
    })
  };

  const formSetMarquilla = () => {
    form.setFieldValue('marquille', marquilla.join(''));
  };



  const FormEdit = () => {
    return (
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Row wrap='true' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={24} span={24}>
            <Form.Item
              label={t('deviceNew.deviceName')}
              name='name'
              initialValue={device.name}
              messageVariables={{ nameField: t('deviceNew.deviceName') }}
              rules={[
                { required: true, message: t('formValidator.mandatory') },
              ]}
            >
              <Input placeholder={t('deviceNew.placeHolderDeviceName')} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} span={24}>
            <Form.Item
              initialValue={device.description}
              label={t('deviceNew.deviceDescription')}
              name='description'
              messageVariables={{
                nameField: t('deviceNew.deviceDescription'),
              }}
              rules={[
                { required: true, message: t('formValidator.mandatory') },
              ]}
            >
              <Input
                placeholder={t('deviceNew.placeHolderDeviceDescription')}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={11} lg={7} span={12}>
            <Form.Item
              initialValue={device.brand}
              label={t('deviceNew.brand')}
              name='brand'
              messageVariables={{ nameField: t('deviceNew.brand') }}
              rules={[
                { required: true, message: t('formValidator.mandatory') },
              ]}
            >
              <Input placeholder={t('deviceNew.brandPlaceHolder')} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={11} lg={7} span={12}>
            <Form.Item
              initialValue={device.power}
              label={t('deviceNew.power')}
              name='power'
              messageVariables={{ nameField: t('deviceNew.power') }}
              rules={[
                { required: true, message: t('formValidator.mandatory') },
              ]}
            >
              <Input placeholder={t('deviceNew.powerPlaceHolder')} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={11} lg={4} span={12}>
            <Form.Item
              label={t('deviceNew.dateInstallating')}
              name='installationDate'
              messageVariables={{ nameField: t('deviceNew.dateInstallating') }}
            >
              <DatePicker
                defaultValue={dayjs(device.installationDate, 'YYYY-MM-DD')}
                onChange={(date, dateString) =>
                  onChangInstallationDate(date, dateString, 'installationDate')
                }
                placeholder={t('deviceNew.dateInstallatingPlaceHolder')}
                size='large'
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={11} lg={3} span={12}>
            <Form.Item
              label={t('deviceNew.manufacturingyear')}
              name='manufacturingDate'
              messageVariables={{ nameField: t('deviceNew.manufacturingyear') }}
            >
              <DatePicker
                defaultValue={dayjs(device.manufacturingDate, 'YYYY-MM-DD')}
                placeholder={t('deviceNew.manufacturingyearPlaceHolder')}
                onChange={onChangManufacturingDate}
                style={{
                  width: '100%',
                }}
                size='large'
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={11} lg={7} span={12}>
            <Form.Item
              label={t('deviceNew.code')}
              initialValue={device.fkCode}
              name='fkCode'
              messageVariables={{ nameField: t('deviceNew.code') }}
              rules={[
                { required: true, message: t('formValidator.mandatory') },
              ]}
            >
              <Select
                options={code}
                size='large'
                placeholder={t('deviceNew.codeSelect')}
                onChange={(value, option) => onChangeCode(option.label)}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={11} lg={7} span={12}>
            <Form.Item
              label={t('deviceNew.deviceZone')}
              initialValue={device.fkUuidZone}
              name='fkUuidZone'
              messageVariables={{ nameField: t('deviceNew.deviceZone') }}
              rules={[
                { required: true, message: t('formValidator.mandatory') },
              ]}
            >
              <Select options={zones} onChange={onChangeZone} size='large' />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={11} lg={4} span={12}>
            <Form.Item
              initialValue={device.consecutive}
              label={t('deviceNew.consecutive')}
              name='consecutive'
              messageVariables={{ nameField: 'consecutive' }}
              rules={[
                { required: true, message: t('formValidator.mandatory') },
                {
                  type: 'number',
                  transform: validNumber,
                  message: t('formValidator.phone'),
                },
              ]}
            >
              <Input
                onKeyUp={handleInputChange}
                size='large'
                placeholder={t('deviceNew.consecutivePlaceholder')}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={11} lg={3} span={12}>
            <Form.Item
              initialValue={device.marquille}
              label={t('deviceNew.marquilla')}
              name='marquille'
              messageVariables={{ nameField: 'Marquilla' }}
              rules={[
                { required: true, message: t('formValidator.mandatory') },
              ]}
            >
              <Input
                defaultValue={device.marquille}
                size='large'
                readOnly={true}
                placeholder={t('deviceNew.placeHolderLatitud')}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={11} lg={7} span={12}>
            <Form.Item
              label={t('deviceNew.lat')}
              name='latitud'
              initialValue={device.latitud}
              messageVariables={{ nameField: 'Latitud' }}
              rules={[
                { required: true, message: t('formValidator.mandatory') },
                {
                  type: 'number',
                  transform: validNumber,
                  message: t('formValidator.phone'),
                },
              ]}
            >
              <Input
                size='large'
                placeholder={t('deviceNew.placeHolderLatitud')}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={11} lg={7} span={12}>
            <Form.Item
              label={t('deviceNew.long')}
              name='longitud'
              initialValue={device.longitud}
              messageVariables={{ nameField: 'Longitud' }}
              rules={[
                { required: true, message: t('formValidator.mandatory') },
                {
                  type: 'number',
                  transform: validNumber,
                  message: t('formValidator.phone'),
                },
              ]}
            >
              <Input
                size='large'
                placeholder={t('deviceNew.placeHolderLongitud')}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={11} lg={7} span={12}>
            <Form.Item
              label={t('zones.state')}
              name='isActive'
              initialValue={device.isActive}
              valuePropName='checked'
              messageVariables={{ nameField: t('zones.state') }}
              rules={[
                { required: true, message: t('formValidator.mandatory') },
              ]}
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        {contextHolder}
        <Button className='mb-2' shape='round' htmlType='submit' type='primary'>
          {t('deviceEdit.saveChange')}
        </Button>
      </Form>
    );
  };

  const items = [
    {
      key: 1,
      label: t('deviceEdit.deviceTabs'),
      children: <FormEdit />,
    },
  ];

  return (
    <Content>
      <Card bordered={false}>
        <Tabs items={items} key={1} />
      </Card>
    </Content>
  );
}
