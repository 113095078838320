import Keycloak from "keycloak-js";
const keycloakAuth = new Keycloak({
  url: "https://account.idtolu.net/",
  realm: "IDT-IDT-DTC-RID-001",
  clientId: "IDT-IDT-DTC-IOT-005",

  // url: `${process.env.REACT_APP_MS_URL_SERVER}`,
  // realm: `${process.env.REACT_APP_MS_REALM}`,
  // clientId: `${process.env.REACT_APP_MS_CLIENTID}`,
});
export default keycloakAuth;
