import { Layout, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";

const { Footer: AntFooter } = Layout;

export default function Footer() {
  const { t } = useTranslation("global");
  return (
    <AntFooter>
      <Row>
        <Col
          xs={24}
          md={24}
          lg={24}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="copyright">
            © {moment().year()}
            <a
              href="https://idtolu.com/"
              className="font-weight-bold"
              target="_blank"
              rel="noreferrer"
            >
              ID TOLU
            </a>
            {t("footer.tdr")}
          </div>
        </Col>
      </Row>
    </AntFooter>
  );
}
