import {
  Switch,
  Spin,
  Row,
  Col,
  Card,
  Radio,
  Divider,
  Space,
  Tooltip
} from 'antd';
import { useEffect, useState } from 'react';
import useMeasurement from '../hooks/hook-measurement';
import moment from 'moment';
import { TableDevice } from './tableDevice';
import useDimerization from '../hooks/hook-demerization';
import SVGComponent from './newLamp';

const loading = {
  LOADING: true,
  FINISHED: false,
};

export default function Dimerization({ t, device }) {
  const { lastMeasurement } = useMeasurement(device?.devEui);
  const {
    loadingSpining,
    setLoadingSpining,
    fetchPostControl,
    fetchPutDevice,
    messageSend,
    contextHolder,
  } = useDimerization(device?.devEui);
  const [currentDevice, setCurrentDevice] = useState({});
  const [dataTable, setdataTable] = useState([]);

  useEffect(() => {
    if (device) {
      setCurrentDevice(device);
      setdataTable([
        {
          key: '1',
          name: currentDevice?.name,
          marquille: currentDevice?.marquille,
          power: `${currentDevice?.power} W`,
          state: currentDevice?.state,
          lastMeasurementDate: moment(lastMeasurement?.formatt_date).format(
            'YYYY-MM-DD HH:mm A'
          ),
        },
      ]);
    }
  }, [device, lastMeasurement]);

  //Cambiar el modo manual a automatico
  const handleSwitchMode = (value) => {
    const action = value ? 'MANUAL' : 'AUTO';
    setLoadingSpining(loading.LOADING);
    setTimeout(() => {
      Promise.all([
        fetchPostControl({ action }),
        fetchPutDevice({ mode: action }),
      ])
        .then(([, responsePut]) => {
          if (responsePut.status === 200) {
            setCurrentDevice(responsePut.data);
            messageSend.open({
              type: 'success',
              content: t('deviceEdit.sucessUpdated'),
            });
          }
        })
        .finally(() => setLoadingSpining(loading.FINISHED));
    }, 5000);
  };
  //Enviar comando que apage
  const handleSwitchOnChange = (value) => {
    if (currentDevice.mode === 'MANUAL') {
      const state = value ? 'ON' : 'OFF';
      const action = value ? 'R-ON' : 'R-OFF';
      setLoadingSpining(loading.LOADING);
      setTimeout(() => {
        Promise.all([fetchPostControl({ action }), fetchPutDevice({ state })])
          .then(([, responsePut]) => {
            if (responsePut.status === 200) {
              setCurrentDevice(responsePut.data);
              messageSend.open({
                type: 'success',
                content: t('deviceEdit.sucessUpdated'),
              });
            }
          })
          .finally(() => setLoadingSpining(loading.FINISHED));
      }, 5000);
    }
  };
  //enviar comando de dimerizar
  const handlerDimerization = (event) => {
    const value = event.target.value;
    setLoadingSpining(loading.LOADING);
    setTimeout(() => {
      Promise.all([
        fetchPostControl({ action: value }),
        fetchPutDevice({ dimerization: value }),
      ])
        .then(([, responsePut]) => {
          if (responsePut.status === 200) {
            setCurrentDevice(responsePut.data);
            messageSend.open({
              type: 'success',
              content: t('deviceEdit.sucessUpdated'),
            });
          }
        })
        .finally(() => setLoadingSpining(loading.FINISHED));
    }, 5000);
  };
  //enviar comando de cambiar envio de mensaje
  const handlerMessage = (event) => {
    const value = event.target.value;
    setLoadingSpining(loading.LOADING);
    setTimeout(() => {
      Promise.all([
        fetchPostControl({ action: value }),
        fetchPutDevice({ message: value }),
      ])
        .then(([, responsePut]) => {
          if (responsePut.status === 200) {
            setCurrentDevice(responsePut.data);
            messageSend.open({
              type: 'success',
              content: t('deviceEdit.sucessUpdated'),
            });
          }
        })
        .finally(() => setLoadingSpining(loading.FINISHED));
    }, 5000);
  };

  return (
    <Spin spinning={loadingSpining}>
      <TableDevice t={t} dataTable={dataTable} />
      <div>
        {contextHolder}
        <div className='container__dimerization'>
          <Switch
            defaultChecked={currentDevice?.mode === 'MANUAL' ? true : false}
            checked={currentDevice?.mode === 'MANUAL' ? true : false}
            checkedChildren={t('lamp.manual')}
            unCheckedChildren={t('lamp.automatic')}
            onChange={handleSwitchMode}
          />
          <Divider />
          <div className='container__dimerization-lamp'>
            <SVGComponent
              color={currentDevice?.state === 'ON' ? '#FFF212' : '#B6B5A7'}
            />
          </div>
          <div className='container__dimerization-switch'>
            <Space>
              <Switch
                defaultChecked={currentDevice?.state === 'ON' ? true : false}
                checked={currentDevice?.state === 'ON' ? true : false}
                checkedChildren='ON'
                unCheckedChildren='OFF'
                onChange={handleSwitchOnChange}
              />
            </Space>
          </div>
        </div>
        <Row
          justify='center'
          wrap='true'
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col xs={24} sm={24} md={24} lg={10} span={12}>
            <Card
              title={t('lamp.dimerizationTaps')}
              style={{ textAlign: 'center' }}
            >
              <Radio.Group
                defaultValue={currentDevice?.dimerization}
                value={currentDevice?.dimerization}
                buttonStyle='solid'
                onChange={handlerDimerization}
              >
                <Tooltip title={t('lamp.high')}>
                  <Radio.Button value='ALTO'>100 %</Radio.Button>
                </Tooltip>
                <Tooltip title={t('lamp.middle')}>
                  <Radio.Button value='INTERM'>75 %</Radio.Button>
                </Tooltip>
                <Tooltip title={t('lamp.half')}>
                  <Radio.Button value='MEDIO'>50 %</Radio.Button>
                </Tooltip>
                <Tooltip title={t('lamp.low')}>
                  <Radio.Button value='BAJA'>10 %</Radio.Button>
                </Tooltip>
              </Radio.Group>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} span={12}>
            <Card title={t('lamp.messages')} style={{ textAlign: 'center' }}>
              <Radio.Group
                defaultValue={currentDevice?.message}
                value={currentDevice?.message}
                buttonStyle='solid'
                onChange={handlerMessage}
              >
                <Radio.Button value='T=1M'>{t('lamp.tm1')}</Radio.Button>
                <Radio.Button value='T=3M'>{t('lamp.tm3')}</Radio.Button>
                <Radio.Button value='T=5M'>{t('lamp.tm5')}</Radio.Button>
                <Radio.Button value='T=15M'>{t('lamp.tm15')}</Radio.Button>
              </Radio.Group>
            </Card>
          </Col>
        </Row>
      </div>
    </Spin>
  );
}
