import { Button, Col, Form, Input, Row, Select, message } from "antd";
import { useTranslation } from "react-i18next";
import requets from "../../../api";
import { useEffect, useState } from "react";
import { getUUIDClientBack, getUUIDClientFront } from "../../../config.dev";
import { useKeycloak } from "@react-keycloak/web";

export default function FormUser({getUsers}) {

    const { keycloak } = useKeycloak();
    const [messageSend, contextHolder] = message.useMessage();
    const { t } = useTranslation("global");
    const req = requets(keycloak.token);
    const [form] = Form.useForm();
    const [options, setOptions] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const [frontResponse, backResponse] = await Promise.all([
                    req.get(`/user/role/client/${getUUIDClientFront()}`),
                    req.get(`/user/role/client/${getUUIDClientBack()}`)
                ]);

                const frontData = frontResponse.data;
                const backData  = backResponse.data;

                const options = frontData.map(item => {
                    const match = backData.find(value => item.name === value.name);
                    if (match) {
                        return {
                            value: JSON.stringify({ name: item.name, idFront: item.id, idBack: match.id }),
                            label: item.name.toUpperCase()
                        }
                    }
                });

                setOptions(options);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }

        fetchData();

    }, []);




    const onFinish = (value) => {
        const valuesForm = {
            ...value,
            pais: '7ec97b47-76b3-4115-9b15-07fd57712a1d',
            roles: JSON.parse(value.roles),
        }

        req.post('/user/', valuesForm).then(response => {
            if (response.status === 200) {
                messageSend.open({
                    type: "success",
                    content: t("user.form.successCreated"),
                });
                form.resetFields();
                getUsers();
            }
        });
    }
    return (
        <>
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row wrap="true" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={11} md={11} lg={12} span={8} className='row-space'>
                        <Form.Item label={t('user.form.name')}
                            name="name"
                            messageVariables={{ nameField: t("user.form.name") }}
                            rules={[
                                { required: true, message: t('formValidator.mandatory') }
                            ]}>
                            <Input placeholder={t('user.form.namePlaceHolder')} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={11} md={11} lg={8} span={8} className='row-space'>
                        <Form.Item label={t('user.form.surname')}
                            name="lastName"
                            messageVariables={{ nameField: t("user.form.surname") }}
                            rules={[
                                { required: true, message: t('formValidator.mandatory') }
                            ]}>
                            <Input placeholder={t('user.form.surnamePlaceHolder')} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={11} md={11} lg={12} span={8} className='row-space'>
                        <Form.Item label={t('user.form.email')}
                            name="email"
                            messageVariables={{ nameField: t("user.form.email") }}
                            rules={[
                                {
                                    pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                    message: t('formValidator.email')
                                },
                                { required: true, message: t('formValidator.mandatory') }
                            ]}>
                            <Input placeholder={t('user.form.emailPlaceHolder')} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={11} md={11} lg={8} span={8} className='row-space'>
                        <Form.Item label={t('user.form.password')}
                            name="password"
                            messageVariables={{ nameField: t("user.form.password") }}
                            rules={[
                                { required: true, message: t('formValidator.mandatory') },
                                {
                                    min: 8,
                                    message: t('formValidator.min'),
                                },
                            ]}>
                            <Input type="password" placeholder={t('user.form.passwordPlaceHolder')} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={11} md={11} lg={6} span={8} className='row-space'>
                        <Form.Item label={t('user.form.phone')}
                            name="phone"
                            messageVariables={{ nameField: t("user.form.phone") }}
                            rules={[
                                { required: true, message: t('formValidator.mandatory') }
                            ]}>
                            <Input placeholder={t('user.form.phonePlaceHolder')} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={11} md={11} lg={6} span={8}>
                        <Form.Item label={t('user.form.rol')}
                            name="roles"
                            messageVariables={{ nameField: t("user.form.rol") }}
                            rules={[
                                { required: true, message: t('formValidator.mandatory') }
                            ]}>
                            <Select size='large' options={options} placeholder={t('user.form.rolPlaceHolder')} />
                        </Form.Item>
                    </Col>
                </Row>
                {contextHolder}
                <Button className="mb-2"  shape="round" htmlType="submit" type="primary">{t('deviceEdit.saveChange')}</Button>
            </Form>
        </>
    );
}