import { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Layout,
  Row,
  Select,
  Tabs,
  message,
  Upload,
  Button,
  Form,
  Input,
  DatePicker,
} from 'antd';
import requets from '../../api';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { InboxOutlined } from '@ant-design/icons';
import Scanner from './components/scanner';
import { server } from '../../api/service/server/axios';
import moment from 'moment';

const { Dragger } = Upload;
const { Content } = Layout;
export default function DeviceNew() {
  const { keycloak } = useKeycloak();
  const req = requets(keycloak.token);
  const { t } = useTranslation('global');
  const [messageSend, contextHolder] = message.useMessage();
  const [profiles, setProfiles] = useState([]);
  const [zones, setZones] = useState([]);
  const [code, setCode] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [marquilla, setMarquilla] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        form.setFieldsValue({ latitud: position.coords.latitude });

        form.setFieldsValue({ longitud: position.coords.longitude });
      });
    }
    req.get('/device-profile').then((response) => {
      setProfiles(
        response.data.map((item) => {
          return {
            value: item.uuid,
            label: item.name,
          };
        })
      );
    });

    req.get('/zone').then((response) => {
      setZones(response.data);
    });

    req.get('/code').then((response) => {
      setCode(response.data);
    });
  }, []);

  

  const props = {
    name: 'file',
    multiple: true,
    action: server() + '/device/file/',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const onFinish = (value) => {
    value.location = [value.latitud, value.longitud];
    delete value.latitud;
    delete value.longitud;
 
 
    value.installationDate = moment(new Date(value.installationDate)).format('YYYY-MM-DD');
    value.manufacturingDate = moment(new Date(value.manufacturingDate)).format('YYYY-MM-DD');
    req.post('/device/', value).then((response) => {
      if (response.status === 201) {
        messageSend.open({
          type: 'success',
          content: t('device.sucessCreated'),
        });
        form.resetFields();
      }
    });
  };

  const validNumber = (value) => {
    if (!isNaN(value)) {
      return parseFloat(value);
    }
    return value;
  };

  useEffect(() => {
      formSetMarquilla();
  }, [marquilla]) 
  
  const onChangeCode = (value) =>  {
    setMarquilla([value, ...marquilla.slice(1)])
  } 

  const onChangeZone = (value, record) =>  {
    const updatedValues = marquilla.length >= 2 ? [marquilla[0], record.code, ...marquilla.slice(2)] : [marquilla[0], record.code];
    setMarquilla(updatedValues);
  }

  const handleInputChange = (e) => {
    if (marquilla.length >= 2) {
      setMarquilla([marquilla[0], marquilla[1], e.target.value]);
    } else {
      setMarquilla([...marquilla, e.target.value]);
    }
  }

  const formSetMarquilla = () => {
    form.setFieldValue('marquille', marquilla.join(''));
  }

  const FormNew = () => {
    return (
      <>
        <Form layout='vertical' form={form} onFinish={onFinish}>
          <Row wrap='true' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={24} lg={24} span={24}>
              <Form.Item
                label={t('deviceNew.deviceName')}
                name='name'
                messageVariables={{ nameField: t('deviceNew.deviceName') }}
                rules={[
                  { required: true, message: t('formValidator.mandatory') },
                ]}
              >
                <Input placeholder={t('deviceNew.placeHolderDeviceName')} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} span={24}>
              <Form.Item
                label={t('deviceNew.deviceDescription')}
                name='description'
                messageVariables={{
                  nameField: t('deviceNew.deviceDescription'),
                }}
                rules={[
                  { required: true, message: t('formValidator.mandatory') },
                ]}
              >
                <Input
                  placeholder={t('deviceNew.placeHolderDeviceDescription')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={11} lg={7} span={12}>
              <Form.Item
                label={t('deviceNew.deviceEui')}
                name='devEui'
                messageVariables={{ nameField: t('deviceNew.deviceEui') }}
                rules={[
                  { required: true, message: t('formValidator.mandatory') },
                ]}
              >
                <Input placeholder={t('deviceNew.placeHolderDeviceEui')} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={11} lg={7} span={12}>
              <Form.Item
                label='APP KEY'
                name='appKey'
                messageVariables={{ nameField: 'APP KEY' }}
                rules={[
                  { required: true, message: t('formValidator.mandatory') },
                ]}
              >
                <Input
                  placeholder={t('deviceNew.placeHolderDeviceAppkey')}
                  value='08edc45174e36d2a375b865e40ee448t'
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={11} lg={7} span={12}>
              <Form.Item
                label={t('deviceNew.deviceEuiJoin')}
                name='joinEui'
                messageVariables={{ nameField: t('deviceNew.deviceEuiJoin') }}
                rules={[
                  { required: true, message: t('formValidator.mandatory') },
                ]}
              >
                <Input
                  placeholder={t('deviceNew.placeHolderDeviceEuiJoin')}
                  value='80a8b15474ad1e3r'
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={11} lg={7} span={12}>
              <Form.Item
                label={t('deviceNew.brand')}
                name='brand'
                messageVariables={{ nameField: t('deviceNew.brand') }}
                rules={[
                  { required: true, message: t('formValidator.mandatory') },
                ]}
              >
                <Input placeholder={t('deviceNew.brandPlaceHolder')} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={11} lg={7} span={12}>
              <Form.Item
                label={t('deviceNew.power')}
                name='power'
                messageVariables={{ nameField: t('deviceNew.power') }}
                rules={[
                  { required: true, message: t('formValidator.mandatory') },
                ]}
              >
                <Input
                  placeholder={t('deviceNew.powerPlaceHolder')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={11} lg={4} span={12}>
              <Form.Item
                label={t('deviceNew.dateInstallating')}
                name='installationDate'
                messageVariables={{ nameField: t('deviceNew.dateInstallating') }}
                rules={[
                  { required: true, message: t('formValidator.mandatory') },
                ]}
              >
              <DatePicker placeholder={t('deviceNew.dateInstallatingPlaceHolder')} size='large' style={{
                  width: '100%'
                }}/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={11} lg={3} span={12}>
              <Form.Item
                label={t('deviceNew.manufacturingyear')}
                name='manufacturingDate'
                messageVariables={{ nameField: t('deviceNew.manufacturingyear') }}
                rules={[
                  { required: true, message: t('formValidator.mandatory') }
                ]}
              >
                <DatePicker  placeholder={t('deviceNew.manufacturingyearPlaceHolder')} style={{
                  width: '100%'
                }} size='large' /> 
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={11} lg={7} span={12}>
              <Form.Item
                label={t('deviceNew.code')}
                name='fkCode'
                messageVariables={{ nameField: t('deviceNew.code') }}
                rules={[
                  { required: true, message: t('formValidator.mandatory') },
                ]}
              >
               <Select options={code} size='large' placeholder={ t('deviceNew.codeSelect') } onChange={(value, option) => onChangeCode(option.label)}></Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={11} lg={7} span={12}>
              <Form.Item
                label={t('deviceNew.deviceZone')}
                name='fkUuidZone'
                messageVariables={{ nameField: t('deviceNew.deviceZone') }}
                rules={[
                  { required: true, message: t('formValidator.mandatory') },
                ]}
              >
                <Select options={zones} onChange={onChangeZone} size='large' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={11} lg={4} span={12}>
              <Form.Item
                label={t('deviceNew.consecutive')}
                name='consecutive'
                messageVariables={{ nameField: 'consecutive' }}
                rules={[
                  { required: true, message: t('formValidator.mandatory') },
                  {
                    type: 'number',
                    transform: validNumber,
                    message: t('formValidator.phone'),
                  },
                ]}
              >
                <Input
                  onKeyUp={handleInputChange}
                  size='large'
                  placeholder={t('deviceNew.consecutivePlaceholder')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={11} lg={3} span={12}>
              <Form.Item
                label={t('deviceNew.marquilla')}
                name='marquille'
                messageVariables={{ nameField: 'Marquilla' }}
                rules={[
                  { required: true, message: t('formValidator.mandatory') }
                ]}
              >
                <Input
                  size='large'
                  readOnly={true}
                  placeholder={t('deviceNew.placeHolderLatitud')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={11} lg={7} span={12}>
              <Form.Item
                label={t('deviceNew.lat')}
                name='latitud'
                messageVariables={{ nameField: 'Latitud' }}
                rules={[
                  { required: true, message: t('formValidator.mandatory') },
                  {
                    type: 'number',
                    transform: validNumber,
                    message: t('formValidator.phone'),
                  },
                ]}
              >
                <Input
                  size='large'
                  placeholder={t('deviceNew.placeHolderLatitud')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={11} lg={7} span={12}>
              <Form.Item
                label={t('deviceNew.long')}
                name='longitud'
                messageVariables={{ nameField: 'Longitud' }}
                rules={[
                  { required: true, message: t('formValidator.mandatory') },
                  {
                    type: 'number',
                    transform: validNumber,
                    message: t('formValidator.phone'),
                  },
                ]}
              >
                <Input
                  size='large'
                  placeholder={t('deviceNew.placeHolderLongitud')}
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={12}
              md={11}
              lg={14}
              span={15}
              className='row-space'
            >
              <Form.Item
                label={t('deviceNew.deviceProfile')}
                name='fkUuidDeviceProfile'
                messageVariables={{ nameField: t('deviceNew.deviceProfile') }}
                rules={[
                  { required: true, message: t('formValidator.mandatory') },
                ]}
              >
                <Select options={profiles} size='large'></Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            {contextHolder}
            <Button
              className='mb-2'
              shape='round'
              htmlType='submit'
              type='primary'
            >
              {t('deviceEdit.saveChange')}
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  };

  const FormMassive = () => {
    return (
      <>
        <div className='massive-container'>
          <div className='massive-item__dragger'>
            <Dragger {...props}>
              <p className='ant-upload-drag-icon'>
                <InboxOutlined />
              </p>
              <p className='ant-upload-text'>{t('deviceNew.messageUpload')}</p>
              <p className='ant-upload-hint'>
                {t('deviceNew.messageUploadSupport')}
              </p>
            </Dragger>
          </div>
        </div>
      </>
    );
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const items = [
    {
      key: 1,
      label: 'Scanner',
      children: <Scanner form={form} handleTabChange={handleTabChange} />,
    },
    {
      key: 2,
      label: t('deviceNew.deviceTabs'),
      children: <FormNew />,
    },
    {
      key: 3,
      label: t('deviceNew.deviceTabsMassive'),
      children: <FormMassive />,
    },
  ];

  return (
    <Content>
      <Card bordered={false}>
        <Tabs
          activeKey={activeTab}
          onTabClick={handleTabChange}
          items={items}
        />
      </Card>
    </Content>
  );
}
