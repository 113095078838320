import { Button, Col, Form, Input, Row, Select, Switch, message } from "antd";
import { useTranslation } from "react-i18next";
import requets from "../../../api";
import { useKeycloak } from "@react-keycloak/web";

export default function FormEdit(props) {
    const { t } = useTranslation("global");
    const { keycloak } = useKeycloak();
    const req = requets(keycloak.token);
    const [messageSend, contextHolder] = message.useMessage();
    const createZone = (value) => {
        req.post("/zone/", value).then((response) => {
            if (response.status === 201) {
                props.onChangeZonesTable();

                messageSend.open({
                    type: "success",
                    content: t("zones.sucessCreated"),
                });

                props.form.resetFields();
            }
        });
    };

    const updateZone = (value) => {
        req.put(`/zone/${props.uuid}`, value).then((response) => {
            if (response.status === 200) {
                props.onChangeZonesTable();

                messageSend.open({
                    type: "success",
                    content: t("zones.sucessUpdated"),
                });

                props.form.resetFields();
                props.setUuid("");
            }
        });
    };

    const onFinish = (value) => {
        props.uuid ? updateZone(value) : createZone(value);
    };

    const validNumber = (value) => {
        if (!isNaN(value)) {
          return parseFloat(value);
        }
        return value;
      };

    return (
        <>
            <Form layout="vertical" onFinish={onFinish} form={props.form}>
                <Row wrap="true" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={4} span={4} className="row-space">
                        <Form.Item
                            label={t("zones.department")}
                            name="fkDepartmentUuid"
                            messageVariables={{ nameField: t("zones.department") }}
                            initialValue={props.departament[0]}
                            rules={[
                                { required: true, message: t("formValidator.mandatory") },
                            ]}
                        >
                            <Select
                                size="large"
                                placeholder={t("zones.placeHolderDepartmentName")}
                                onChange={props.onChangeDepartament}
                            >
                                {props.departament.map((option) => (
                                    <Select.Option
                                        key={option.value}
                                        value={option.value}
                                        counties={option.counties}
                                    >
                                        {option.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={4} span={4} className="row-space">
                        <Form.Item
                            label={t("zones.municipality")}
                            name="fkCountyUuid"
                            messageVariables={{ nameField: t("zones.municipality") }}
                            rules={[
                                { required: true, message: t("formValidator.mandatory") },
                            ]}
                        >
                            <Select
                                size="large"
                                placeholder={t("zones.placeHolderMunicipality")}
                            >
                                {props.counties.map((option) => (
                                    <Select.Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={4} span={4} className="row-space">
                        <Form.Item
                            label={t("zones.code")}
                            name="code"
                            messageVariables={{ nameField: t("zones.code") }}
                            rules={[
                                { required: true, message: t("formValidator.mandatory") },
                                {
                                    type: 'number',
                                    transform: validNumber,
                                    message: t('formValidator.phone'),
                                }
                            ]}
                        >
                            <Input placeholder={t("zones.codePlaceHolder")} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} span={6} className="row-space">
                        <Form.Item
                            label={t("zones.zone")}
                            name="label"
                            messageVariables={{ nameField: t("zones.zone"), min: 2, max: 64 }}
                            rules={[
                                { required: true, message: t("formValidator.mandatory") },
                                { min: 2, message: t("formValidator.min") },
                                { max: 64, message: t("formValidator.max") },
                            ]}
                        >
                            <Input placeholder={t("zones.placeHolderZonaName")} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={4} span={4} className="row-space">
                        <Form.Item
                            label={t("zones.state")}
                            name="state"
                            valuePropName="checked"
                            messageVariables={{ nameField: t("zones.state") }}
                            rules={[
                                { required: true, message: t("formValidator.mandatory") },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    {contextHolder}
                    <Button  shape="round" className="mb-2" htmlType="submit" type="primary">
                        {t("deviceEdit.saveChange")}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
