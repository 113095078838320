import { Button, Col, Form, Input, Row, Space } from "antd";
import { useTranslation } from "react-i18next";

export default function FormConfig(props) {

    const { t } = useTranslation("global");

    const validNumber = (value) => {
        if (!isNaN(value)) {
            return parseFloat(value);
        }
        return value;
    }

    return (

        <>
            <Form layout="vertical" form={props.form} onFinish={props.onFinish}>
                <Row wrap="true" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={24} md={11} lg={4} span={4} className='row-space'>
                        <Space>
                            <span style={{paddingRight: '10px'}}>{t('config.current')}</span>
                            <Space.Compact>
                                <Form.Item label={t('config.min')}
                                    name={['current', 0]}
                                    messageVariables={{ nameField: t("config.minPlaceHolder") }}
                                    rules={[
                                        { required: true, message: t('formValidator.mandatory') },
                                        {
                                            type: "number", message: t('formValidator.phone'),
                                            transform: validNumber,
                                        }
                                    ]}>
                                    <Input placeholder={t('config.minPlaceHolder')} />
                                </Form.Item>
                                <Form.Item label={t('config.max')}
                                    name={['current', 1]}
                                    messageVariables={{ nameField: t("config.maxPlaceHolder") }}
                                    rules={[
                                        { required: true, message: t('formValidator.mandatory') },
                                        {
                                            type: "number", message: t('formValidator.phone'),
                                            transform: validNumber,
                                        }
                                    ]}>
                                    <Input placeholder={t('config.maxPlaceHolder')} />
                                </Form.Item>
                            </Space.Compact>
                        </Space>
                    </Col>
                    <Col xs={24} sm={24} md={11} lg={4} span={4} className='row-space'>
                        <Space>
                        <span style={{paddingRight: '10px'}}>{t('config.voltage')}</span>
                            <Space.Compact>
                                <Form.Item label={t('config.min')}
                                    name={['voltage', 0]}
                                    messageVariables={{ nameField: t("config.minPlaceHolder") }}
                                    rules={[
                                        { required: true, message: t('formValidator.mandatory') },
                                        {
                                            type: "number", message: t('formValidator.phone'),
                                            transform: validNumber,
                                        }
                                    ]}>
                                    <Input placeholder={t('config.minPlaceHolder')} />
                                </Form.Item>
                                <Form.Item label={t('config.max')}
                                    name={['voltage', 1]}
                                    messageVariables={{ nameField: t("config.maxPlaceHolder") }}
                                    rules={[
                                        { required: true, message: t('formValidator.mandatory') },
                                        {
                                            type: "number", message: t('formValidator.phone'),
                                            transform: validNumber,
                                        }
                                    ]}>
                                    <Input placeholder={t('config.maxPlaceHolder')} />
                                </Form.Item>
                            </Space.Compact>
                        </Space>
                    </Col>
                    <Col  xs={24} sm={24} md={11} lg={4} span={4} className='row-space'>
                        <Space>
                        <span style={{paddingRight: '10px'}}>{t('config.powerFactor')}</span>
                            <Space.Compact>
                                <Form.Item label={t('config.min')}
                                    name={['powerFactor', 0]}
                                    messageVariables={{ nameField: t("config.minPlaceHolder") }}
                                    rules={[
                                        { required: true, message: t('formValidator.mandatory') },
                                        {
                                            type: "number", message: t('formValidator.phone'),
                                            transform: validNumber,
                                        }
                                    ]}>
                                    <Input placeholder={t('config.minPlaceHolder')} />
                                </Form.Item>
                                <Form.Item label={t('config.max')}
                                    name={['powerFactor', 1]}
                                    messageVariables={{ nameField: t("config.maxPlaceHolder") }}
                                    rules={[
                                        { required: true, message: t('formValidator.mandatory') },
                                        {
                                            type: "number", message: t('formValidator.phone'),
                                            transform: validNumber,
                                        }
                                    ]}>
                                    <Input placeholder={t('config.maxPlaceHolder')} />
                                </Form.Item>
                            </Space.Compact>
                        </Space>
                    </Col>
                    <Col xs={24} sm={24} md={11} lg={4} span={4} className='row-space'>
                        <Space>
                        <span style={{paddingRight: '10px'}}>{t('config.power')}</span>
                            <Space.Compact>
                                <Form.Item label={t('config.min')}
                                    name={['power', 0]}
                                    messageVariables={{ nameField: t("config.minPlaceHolder") }}
                                    rules={[
                                        { required: true, message: t('formValidator.mandatory') },
                                        {
                                            type: "number", message: t('formValidator.phone'),
                                            transform: validNumber,
                                        }
                                    ]}>
                                    <Input placeholder={t('config.minPlaceHolder')} />
                                </Form.Item>
                                <Form.Item label={t('config.max')}
                                    name={['power', 1]}
                                    messageVariables={{ nameField: t("config.maxPlaceHolder") }}
                                    rules={[
                                        { required: true, message: t('formValidator.mandatory') },
                                        {
                                            type: "number", message: t('formValidator.phone'),
                                            transform: validNumber,
                                        }
                                    ]}>
                                    <Input placeholder={t('config.maxPlaceHolder')} />
                                </Form.Item>
                            </Space.Compact>
                        </Space>
                    </Col>
                    <Col xs={24} sm={24} md={11} lg={4} span={4} className='row-space'>
                        <Space>
                            <span style={{paddingRight: '10px'}}>{t('config.frequency')}</span>
                            <Space.Compact>
                                <Form.Item label={t('config.min')}
                                    name={['frequency', 0]}
                                    messageVariables={{ nameField: t("config.minPlaceHolder") }}
                                    rules={[
                                        { required: true, message: t('formValidator.mandatory') },
                                        {
                                            type: "number", message: t('formValidator.phone'),
                                            transform: validNumber,
                                        }
                                    ]}>
                                    <Input placeholder={t('config.minPlaceHolder')} />
                                </Form.Item >
                                <Form.Item label={t('config.max')}
                                    name={['frequency', 1]}
                                    messageVariables={{ nameField: t("config.maxPlaceHolder") }}
                                    rules={[
                                        { required: true, message: t('formValidator.mandatory') },
                                        {
                                            type: "number", message: t('formValidator.phone'),
                                            transform: validNumber,
                                        }
                                    ]}>
                                    <Input placeholder={t('config.maxPlaceHolder')} />
                                </Form.Item>
                            </Space.Compact>
                        </Space>
                    </Col>
                    <Col xs={24} sm={24} md={11} lg={4} span={4} className='row-space'>
                        <Space>
                            <span style={{paddingRight: '10px'}}>{t('config.energy')}</span>
                            <Space.Compact>
                                <Form.Item label={t('config.min')}
                                    name={['energy', 0]}
                                    messageVariables={{ nameField: t("config.minPlaceHolder") }}
                                    rules={[
                                        { required: true, message: t('formValidator.mandatory') },
                                        {
                                            type: "number", message: t('formValidator.phone'),
                                            transform: validNumber,
                                        }
                                    ]}>
                                    <Input placeholder={t('config.minPlaceHolder')} />
                                </Form.Item >
                                <Form.Item label={t('config.max')}
                                    name={['energy', 1]}
                                    messageVariables={{ nameField: t("config.maxPlaceHolder") }}
                                    rules={[
                                        { required: true, message: t('formValidator.mandatory') },
                                        {
                                            type: "number", message: t('formValidator.phone'),
                                            transform: validNumber,
                                        }
                                    ]}>
                                    <Input placeholder={t('config.maxPlaceHolder')} />
                                </Form.Item>
                            </Space.Compact>
                        </Space>
                    </Col>
                </Row>
                <Button className="mb-2"  shape="round" type="primary" htmlType="submit">{t('config.saveChange')}</Button>
            </Form>


        </>
    )
}