import { Table, Tag } from 'antd';

export const TableDevice = ({ t, dataTable }) => {
  const fontSize = {
    fontSize: '16px',
  };

  const columns = [
    {
      title: t('lamp.device'),
      dataIndex: 'name',
      key: 'name',
      render: (text = '') => (
        <Tag color='blue' style={fontSize}>
          {text.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: t('lamp.marquille'),
      dataIndex: 'marquille',
      key: 'marquille',
    },
    {
      title: t('lamp.power'),
      dataIndex: 'power',
      key: 'power',
    },
    {
      title: t('lamp.state'),
      dataIndex: 'state',
      key: 'state',
      render: (text = '') => {
        const color = text === 'ON' ? 'success' : 'error';
        text =
          text === 'ON'
            ? t('reports.tableColumn.stateValueOn')
            : t('reports.tableColumn.stateValueOff');
        return (
          <Tag color={color} style={fontSize}>
            {text.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: t('lamp.lastmeasuremt'),
      dataIndex: 'lastMeasurementDate',
      key: 'lastMeasurementDate',
    },
  ];

  return (
    <Table
      scroll={{ x: 'calc(200px + 50%)' }}
      className='hover-off'
      columns={columns}
      dataSource={dataTable}
      pagination={false}
    ></Table>
  );
};
