import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import requets from '../../../api';
import { useSelector } from 'react-redux';

import { useEffect, useState } from 'react';
export default function useLamp() {
  const { keycloak } = useKeycloak();
  const req = requets(keycloak.token);
  const { t } = useTranslation('global');
  const controlPanel = useSelector((state) => state.controlPanel);
  const [data, setData] = useState([]);
  const [device, setDevice] = useState({});
  const [param, setParam] = useState([]);

  useEffect(() => {
    function fetchOneDevice() {
      req.get(`/device/${param}`).then((response) => {
        setDevice(response.data);
      });
    }
    fetchOneDevice();
  }, [param]);

  useEffect(() => {
    async function fetchData() {
      const { date, department, county, zone, devEui } = controlPanel;
      req
        .get('/device/filter/', {
          ...date,
          department,
          county,
          zone,
          devEui,
        })
        .then((response) => {
          setData(response.data);
        });
    }

    fetchData();
  }, [controlPanel]);

  return {
    t,
    data,
    device,
    setDevice,
    param,
    setParam,
  };
}
