import { useRef, useState } from 'react';
import { Button, Input, Space } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";


export default function SearchDataIndex() {


    const { t } = useTranslation("global");
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getObjectValueByPath = (obj, path) => {
        const keys = path.split('.');
        return keys.reduce((value, key) => (value && value[key] !== undefined) ? value[key] : undefined, obj);
    };

    const isValueMatch = (value, searchString) => {
        if (value !== undefined && value !== null) {
            return value.toString().toLowerCase().includes(searchString.toLowerCase());
        }
        return false;
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 10,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={t('searchTable.search')}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        style={{
                            width: 90,
                        }}
                    >
                        {t('searchTable.search')}
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        style={{
                            width: 90,
                        }}
                    >
                        {t('searchTable.reset')}
                    </Button>
                    <Button
                        type="link"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        {t('searchTable.filter')}
                    </Button>
                    <Button
                        type="link"
                        onClick={() => {
                            close();
                        }}
                    >
                        {t('searchTable.close')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => {
            const filteredValue = getObjectValueByPath(record, dataIndex);
            return isValueMatch(filteredValue, value);
        },


        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        }
    });


    return getColumnSearchProps;

}