import { Card, Layout, Table, Tabs, message } from "antd";
import { useTranslation } from "react-i18next";
import columnUser from "./table/data.table";
import FormUser from "./components/form.user";
import { useEffect, useState } from "react";
import requets from "../../api";
import { useKeycloak } from "@react-keycloak/web";

const { Content } = Layout;

export default function User() {

    const { keycloak } = useKeycloak();
    const req = requets(keycloak.token);
    const [messageSend, contextHolder] = message.useMessage();
    const { t } = useTranslation("global");
    const [users, setUsers] = useState([]);

    const getUsers = () => {
        req.get('/user/').then(response => {
            if (response.status === 200) {
                setUsers(response.data);
            }
        })
    }
    useEffect(() => {
        getUsers();
    }, [])


    const deleteUser = (id) => {
        req.delete(`/user/${id}`).then(response => {
            if (response.status === 200) {
                messageSend.open({
                    type: "success",
                    content: t("user.form.successDeleted"),
                });
                getUsers();
            }
        });
    }


    const items = [
        {
            key: '1',
            label: t('user.userTaps'),

            children: <>
                {contextHolder}
                <Table columns={columnUser(deleteUser)} dataSource={users} scroll={{ x: 'calc(700px + 50%)' }} rowKey="id"></Table>
            </>
        },
        {
            key: '2',
            label: t('user.newUserTaps'),
            children: <FormUser getUsers={getUsers}/>
        }
    ];

    return (
        <Content>
            <Card bordered={false}>
                <Tabs defaultActiveKey="1" items={items} />
            </Card>
        </Content>
    );
}